import React from "react";
import { Container, Row, Col } from "reactstrap";
import southAfricaFlag from "assets/img/south-africa-flag.jpg";
import nigeriaFlag from "assets/img/Nigeria.jpg";
import ghanaFlag from "assets/img/ghana.jpg";
import drCongoFlag from "assets/img/dr-congo.jpg";
import zimbabweFlag from "assets/img/zim.jpg";
import cameroonFlag from "assets/img/cam.jpg";
import swazilandFlag from "assets/img/swazi.jpg";
import usaFlag from "assets/img/usa.jpg";

const ScrollingFlags = () => {
  const flags = [
    { country: "South Africa", src: southAfricaFlag },
    { country: "Nigeria", src: nigeriaFlag },
    { country: "Ghana", src: ghanaFlag },
    { country: "DR Congo", src: drCongoFlag },
    { country: "Zimbabwe", src: zimbabweFlag },
    { country: "Cameroon", src: cameroonFlag },
    { country: "Swaziland", src: swazilandFlag },
    { country: "USA", src: usaFlag },
  ];

  return (
    <div className="our-clients" style={{ marginTop: "-30px" }}>
      <Container style={{ marginBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2
              className="title"
              style={{
                fontWeight: "bold",
                marginBottom: "40px",
                marginTop: "60px",
                fontSize: "clamp(18px, 7.5vw, 45px)",
              }}
            >
              WE HAVE TRAINED ARTISTS FROM
            </h2>
          </Col>
        </Row>
        <div className="scrolling-container">
          <div className="scrolling-flags">
            {flags.concat(flags).map((flag, index) => (
              <div key={index} className="countryFlags">
                <img alt={flag.country} src={flag.src} />
              </div>
            ))}
          </div>
        </div>
      </Container>

      <style>
        {`
          .scrolling-container {
            width: 100%;
            overflow: hidden;
            position: relative;
            padding: 10px 0;
          }

          .scrolling-flags {
            display: flex;
            animation: scroll 40s linear infinite;
          }

          .countryFlags {
            flex: 0 0 auto;
            padding: 0 10px;
            width: 200px;
          }

          .countryFlags img {
            width: 100%;
            height: auto;
            border-radius: 8px;
            object-fit: contain;
          }

          @keyframes scroll {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }

          /* Pause animation on hover */
          .scrolling-flags:hover {
            animation-play-state: paused;
          }

          @media (max-width: 768px) {
            .countryFlags {
              width: 150px;
            }
          }

          @media (max-width: 576px) {
            .countryFlags {
              width: 120px;
              padding: 0 5px;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ScrollingFlags;
