import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import instagram from "../../assets/img/instagram.png";
import linkedin from "../../assets/img/linkedin.png";
import youtube from "../../assets/img/youtube.png";

function FooterBlack() {
  return (
    <footer
      className="footer"
      style={{ backgroundColor: "#f4f5f7", padding: "10px 0" }}
    >
      <Container className="px-4 px-lg-5">
        <Row className="align-items-center">
          {/* Brand */}
          <Col xs="12" lg="3" className="text-center text-lg-left mb-2 mb-lg-0">
            <span
              style={{
                color: "black",
                fontWeight: "bold",
                whiteSpace: "nowrap",
              }}
            >
              Elevate Africa Academy
            </span>
          </Col>

          {/* Social Media Icons */}
          <Col
            xs="12"
            lg="3"
            className="d-flex justify-content-center align-items-center mb-2 mb-lg-0"
          >
            <div className="d-flex">
              <Button
                color="link"
                href="https://www.instagram.com/elevate.africa/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                className="p-1 mx-1"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: "24px", height: "24px" }}
                />
              </Button>
              <Button
                color="link"
                href="https://www.linkedin.com/company/elevate-africa-academy/"
                target="_blank"
                rel="noopener noreferrer"
                className="p-1 mx-1"
              >
                <img
                  src={linkedin}
                  alt="LinkedIn"
                  style={{ width: "26px", height: "26px" }}
                />
              </Button>
              <Button
                color="link"
                href="https://www.youtube.com/@elevateafricaacademy9081"
                target="_blank"
                rel="noopener noreferrer"
                className="p-1 mx-1"
              >
                <img
                  src={youtube}
                  alt="Youtube"
                  style={{ width: "26px", height: "26px" }}
                />
              </Button>
            </div>
          </Col>

          {/* Links and Copyright */}
          <Col
            xs="12"
            lg="6"
            className="d-flex justify-content-center justify-content-lg-end align-items-center"
          >
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <Link
                to="/privacy-policy"
                className="mx-2 text-nowrap"
                style={{
                  color: "black",
                  fontSize: "0.9em",
                }}
              >
                Privacy Policy
              </Link>
              <span className="mx-2 text-nowrap" style={{ color: "black" }}>
                |
              </span>
              <Link
                to="/terms-and-conditions"
                className="mx-2 text-nowrap"
                style={{
                  color: "black",
                  fontSize: "0.9em",
                }}
              >
                Terms and Conditions
              </Link>
              <span className="mx-2 text-nowrap" style={{ color: "black" }}>
                |
              </span>
              <span
                className="text-nowrap"
                style={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "0.9em",
                }}
              >
                © {new Date().getFullYear()}
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterBlack;
