import React, { useState, useEffect } from "react";
import { getReviews } from "service/reviews";
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
} from "reactstrap";

function Testimonials() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchReviews = async () => {
      const response = await getReviews();
      const data = response.data;
      setReviews(data);
    };
    fetchReviews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!animating) {
        next();
      }
    }, 8000);

    return () => clearInterval(interval);
  }, [animating]);

  const onExiting = () => setAnimating(true);
  const onExited = () => setAnimating(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === reviews.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? reviews.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <div
      className="section section-testimonials"
      style={{ backgroundColor: "#f6f7ff", paddingTop: "0px" }}
    >
      <Container fluid>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <h2
              style={{
                fontWeight: "bold",
                marginTop: "40px",
                color: "black",
                fontSize: "clamp(18px, 7.5vw, 45px)",
              }}
            >
              WHAT OUR CLIENTS SAY
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto" md="2"></Col>
          <Col md="8">
            <div
              className="page-carousel"
              style={{ marginTop: "0px", marginBottom: "20px" }}
            >
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                {reviews.map((review, key) => (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={key}
                  >
                    <Card
                      className="card-testimonial card-plain"
                      style={{
                        border: "none",
                        marginBottom: "0px",
                        paddingBottom: "0px",
                        paddingTop: "0px",
                      }}
                    >
                      <CardBody
                        style={{ minHeight: "0px", paddingBottom: "0px" }}
                      >
                        <p className="card-description">
                          {review.r_description}
                        </p>
                        <CardFooter>
                          <CardTitle tag="h4">{review.r_name}</CardTitle>
                          <h5 style={{ fontSize: "1.1em" }}>
                            {review.r_title}
                          </h5>
                          <div className=" card-stars">
                            <i
                              aria-hidden={true}
                              className=" fa fa-star mr-1"
                            ></i>
                            <i
                              aria-hidden={true}
                              className=" fa fa-star mr-1"
                            ></i>
                            <i
                              aria-hidden={true}
                              className=" fa fa-star mr-1"
                            ></i>
                            <i
                              aria-hidden={true}
                              className=" fa fa-star mr-1"
                            ></i>
                            <i aria-hidden={true} className=" fa fa-star"></i>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </CarouselItem>
                ))}
                <a
                  className="left carousel-control carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <span className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={(e) => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <span className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </a>
              </Carousel>
            </div>
          </Col>
          <Col className="mr-auto" md="2"></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Testimonials;
