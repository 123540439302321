import React from "react";
import VoiceOverAlumni from "./VoiceOverAlumni";
import PublicSpeakingAlumni from "./PublicSpeakingAlumni";
import VoiceOverAlumniHeader from "components/Headers/VoiceOverAlumniHeader";
import AppNavbar from "components/Navbars/AppNavbar";
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import FooterBlack from "components/Footers/FooterBlack";

function Alumni() {
  const [pillActive, setPillActive] = React.useState("VoiceOver");

  const activeStyle = {
    backgroundColor: "#113B51",
    color: "white",
    borderColor: "#113B51",
  };

  const inactiveStyle = {
    backgroundColor: "white",
    color: "Black",
    borderColor: "#000080",
  };

  return (
    <>
      <AppNavbar />
      <VoiceOverAlumniHeader />
      <div
        className="section section-blog"
        style={{
          backgroundColor: "white",
        }}
      >
        <div>
          <Container>
            <Row>
              <Col md="12">
                <div className="choose-plan">
                  <Nav
                    className="nav-fill justify-content-center"
                    pills
                    role="tablist"
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingTop: "20px",
                      paddingBottom: "15px",
                      borderRadius: "0px",
                    }}
                  >
                    <NavItem
                      style={{
                        paddingBottom: "0px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <NavLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPillActive("VoiceOver");
                        }}
                        style={
                          pillActive === "VoiceOver"
                            ? activeStyle
                            : inactiveStyle
                        }
                      >
                        VOICE OVER
                      </NavLink>
                    </NavItem>
                    <NavItem
                      style={{
                        paddingBottom: "0px",
                        fontSize: "16px",
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <NavLink
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPillActive("PublicSpeaking");
                        }}
                        style={
                          pillActive === "PublicSpeaking"
                            ? activeStyle
                            : inactiveStyle
                        }
                      >
                        PUBLIC SPEAKING
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </Col>
              <Col md="12" style={{ marginTop: "0px" }}>
                <TabContent className="text-center" activeTab={pillActive}>
                  <TabPane tabId="VoiceOver">
                    <div className="space-top" />
                    <Row>
                      <Col md="12">
                        <VoiceOverAlumni />
                      </Col>
                    </Row>
                  </TabPane>

                  <TabPane tabId="PublicSpeaking">
                    <div className="space-top" />
                    <Row>
                      <Col md="12">
                        <PublicSpeakingAlumni />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterBlack />
    </>
  );
}

export default Alumni;
