import React from "react";
import { Container } from "reactstrap";

function ContactUsHeader() {
  return (
    <div
      className="page-header page-header-small"
      style={{
        backgroundImage:
          "url(" + require("assets/img/sections/mic-background.jpg") + ")",
        position: "relative",
        height: "200px", // Adjusted height
        backgroundSize: "cover",
        backgroundPosition: "70% 10%", // Adjusted to move image more to the right
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="filter" />
      <div className="content-center">
        <Container className="text-center">
          <h1
            className="display-3"
            style={{
              fontSize: "clamp(2rem, 6vw, 4rem)", // Clamps the heading font size
            }}
          >
            Get in touch with us
          </h1>
          <h3
            className="lead"
            style={{
              fontSize: "clamp(1rem, 4vw, 2rem)",
            }}
          >
            For inquiries about our services or any questions, please fill the
            form below
          </h3>
        </Container>
      </div>
    </div>
  );
}

export default ContactUsHeader;
