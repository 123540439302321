import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";

// Import images
import elmaImage from "../assets/profilepictures/Elmaprofilepicture.png";
import ponatshegoImage from "../assets/profilepictures/pona.png";
import lihleImage from "../assets/profilepictures/lihle.png";

// Import brand logos
import dstvLogo from "../assets/img/dstv.png";
import disneyLogo from "../assets/img/Disney-Logo.jpg";
import niveaLogo from "../assets/img/Nivea.png";
import netflixLogo from "../assets/img/Netflix_logo.png";

const teamMembers = [
  {
    name: "Elma Akob",
    title: "Founder and CEO",
    description: (
      <>
        Elma Akob is a young and vibrant lady who strives for excellence in all
        spheres of life. She is incredibly passionate about her Continent and
        being the change she wants to see. At 22 years old she is a 3-time
        graduate with a Bcom Law degree, a Communication Management Honours
        degree, and a Masters degree in Strategic Business Management. She is an
        advocate for women empowerment, a Pan African citizen, and a Voice To Be
        reckoned with.
        <br />
        <br />
        She is an international award-winning speaker, TEDx speaker, and
        renowned conversation strategist. Elma is used to being the youngest in
        her spaces but strongly believes that being the "first" is commendable
        but real impact is made through making sure you are not the "last".
      </>
    ),
    image: elmaImage,
    brands: [dstvLogo, disneyLogo, netflixLogo, niveaLogo],
  },

  {
    name: "Ponatshego Sebola",
    title: "Project Operations Director",
    description: (
      <>
        Ponatshego Sebola is a love-spirited individual whose ardour for
        understanding, compassion, and character have shaped who she is. Her
        avidity for self-efficacy, community engagement, and skills development
        have driven her ambition in all that she does.
        <br />
        <br />
        As an academic, she has studied a BA General degree majoring in
        psychology and sociology at the University of Pretoria. Additionally,
        she holds her honours degree at the University of South Africa and to
        date she continues on the trajectory of becoming a counselling
        psychologist. She has also received basic counselling skills with the
        Applied Counselling and Development Institute of South Africa. Moreover,
        she completed a leadership program with both Common Purpose as well as
        the Friedrich-Ebert-Stiftung and UP Autumn School.
        <br />
        <br />
        When it comes to public speaking, Ponatshego has a proven track record.
        From participating in public speaking competitions and eisteddfods
        throughout primary school and high school to representing a university
        organization on a national's stage, Ponatshego has been able to not only
        hone in on this skill but to successfully train speakers as a result of
        her experiences.
      </>
    ),
    image: ponatshegoImage,
  },

  {
    name: "Lihle Rita Khumalo",
    title: "Administrator",
    description: (
      <>
        Lihle is a BCom graduate from the University of Pretoria. She is
        currently studying her BComHons in Strategic Management at the
        University of Johannesburg. Lihle is pursuing a budding career within
        the tech industry.
        <br />
        <br />
        She is an experienced public speaker with a passion for communication.
        Lihle has served in various leadership roles throughout her university
        career. This includes serving in societies such as the Golden Key
        International Society, TedXUniversityofPretoria, and the Tuks Leadership
        and Individual Programme, amongst others.
      </>
    ),
    image: lihleImage,
  },
];

const OurTeamPage = () => {
  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <Container className="mt-5">
        {teamMembers.map((member, index) => (
          <Card
            key={index}
            className="mb-5"
            style={{
              border: "none",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              padding: "20px",
            }}
          >
            <Row noGutters>
              <Col
                xs="12"
                md="4"
                className="d-flex align-items-center justify-content-center p-3"
              >
                <div
                  style={{
                    width: "100%",
                    height: "300px",
                    border: "2px solid",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    borderColor: "white",
                  }}
                >
                  <img
                    src={member.image}
                    alt={member.name}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      height: "auto",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </Col>
              <Col xs="12" md="8">
                <CardBody>
                  <CardTitle
                    tag="h3"
                    style={{
                      fontWeight: "bold",
                      fontSize: "clamp(18px, 4vw, 24px)", // Responsive font size
                    }}
                  >
                    {member.name}
                  </CardTitle>
                  <CardText
                    tag="h5"
                    style={{
                      color: "#6c757d",
                      marginBottom: "10px",
                      fontSize: "clamp(14px, 3vw, 18px)", // Responsive font size
                    }}
                  >
                    {member.title}
                  </CardText>
                  <CardText
                    style={{
                      fontSize: "clamp(14px, 3vw, 16px)", // Responsive font size
                      lineHeight: "1.5",
                    }}
                  >
                    {member.description}
                  </CardText>
                  {member.brands && (
                    <div style={{ marginTop: "20px" }}>
                      <h6 style={{ fontWeight: "bold" }}>
                        Brands Elma has worked with:
                      </h6>
                      <div
                        style={{
                          display: "flex",
                          gap: "30px",
                          marginTop: "10px",
                          flexWrap: "wrap", // Ensure logos wrap on smaller screens
                        }}
                      >
                        {member.brands.map((brand, brandIndex) => (
                          <img
                            key={brandIndex}
                            src={brand}
                            alt="Brand logo"
                            style={{
                              height: "40px",
                              borderRadius: "0px",
                              objectFit: "contain",
                            }}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </CardBody>
              </Col>
            </Row>
          </Card>
        ))}
      </Container>
      <FooterBlack />
    </>
  );
};

export default OurTeamPage;
