import React from "react";

function CleanHeader() {
  return (
    <div
      className="page-header page-header-small"
      style={{
        position: "relative",
        width: "100%",
        height: "400px", // Adjust as needed
        overflow: "hidden",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage:
            "url(" + require("assets/img/sections/mic-background.jpg") + ")",

          backgroundSize: "cover",
          backgroundPosition: "center 100% 10%", // Adjust to position the microphone in view
          backgroundRepeat: "no-repeat",
        }}
      />
      <div
        className="filter"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(10,88,136,0.3)",
        }}
      />
      <div className="content-center">
        {/* Add any content you want to center here */}
      </div>
    </div>
  );
}

export default CleanHeader;
