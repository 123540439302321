import React, { useState } from "react";
import "../../Styling/App.css";
import { createSurvey } from "services/survey.service";
import {
  Container,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";

const CreateSurvey = () => {
  const [s_title, setTitle] = useState("");
  const [s_questions, setQuestions] = useState([
    { question: "", answers: [{ answer: "", weight: "" }], isEditing: true },
  ]);
  const [s_type, setType] = useState("pre_course");
  const [s_category, setCategory] = useState("voice_over");
  const [s_chapter, setChapter] = useState("Chapter 1");
  const [s_published, setPublished] = useState(false);
  const [s_feedback, setFeedbacks] = useState([{ score: "", feedback: "" }]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleQuestionChange = (index, e) => {
    const newQuestions = [...s_questions];
    newQuestions[index][e.target.name] = e.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (qIndex, aIndex, e) => {
    const { name, value } = e.target;
    const newQuestions = [...s_questions];
    newQuestions[qIndex].answers[aIndex][name] =
      name === "weight" ? Number(value) : value; // Convert to number for weight
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    setQuestions([
      ...s_questions,
      { question: "", answers: [{ answer: "", weight: "" }], isEditing: true },
    ]);
  };

  const addAnswer = (qIndex) => {
    const newQuestions = [...s_questions];
    newQuestions[qIndex].answers.push({ answer: "", weight: "" });
    setQuestions(newQuestions);
  };

  const toggleEdit = (index) => {
    const newQuestions = [...s_questions];
    newQuestions[index].isEditing = !newQuestions[index].isEditing;
    setQuestions(newQuestions);
  };

  const handleFeedbackChange = (index, e) => {
    const { name, value } = e.target;
    const newFeedbacks = [...s_feedback];
    newFeedbacks[index][name] = name === "score" ? Number(value) : value;
    setFeedbacks(newFeedbacks);
  };

  const addFeedback = () => {
    setFeedbacks([...s_feedback, { score: "", feedback: "" }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      s_title.trim() === "" ||
      s_questions.some(
        (q) =>
          q.question.trim() === "" ||
          q.answers.some(
            (a) => a.answer.trim() === "" || a.weight === "" || isNaN(a.weight)
          )
      )
    ) {
      alert("Please fill out all fields and ensure weights are valid numbers");
      return;
    }

    try {
      const response = await createSurvey({
        s_title,
        s_questions,
        s_type,
        surveyType: s_category,
        s_published,
        s_category,
        s_chapter,
        s_feedback, // Include feedbacks in the survey creation payload
      });
      if (response.success) {
        alert("Survey created successfully!");
        setTitle("");
        setQuestions([
          {
            question: "",
            answers: [{ answer: "", weight: "" }],
            isEditing: true,
          },
        ]);
        setType("pre_course");
        setCategory("voice_over");
        setChapter("Chapter 1");
        setPublished(false);
        setFeedbacks([{ score: "", feedback: "" }]);
      } else {
        alert("Error creating survey. Please try again.");
      }
    } catch (error) {
      console.error("Error creating survey: ", error);
      alert("Error creating survey. Please try again.");
    }
  };

  return (
    <Container className="mt-5">
      <Card>
        <CardBody>
          <CardTitle
            tag="h2"
            className="text-center mb-4"
            style={{ color: "#4a90e2" }}
          >
            Create Survey
          </CardTitle>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="surveyTitle">Survey Title:</Label>
              <Input
                type="text"
                id="surveyTitle"
                value={s_title}
                onChange={handleTitleChange}
                className="form-control"
              />
            </FormGroup>
            <FormGroup>
              <Label for="surveyType">Survey Type:</Label>
              <Input
                type="select"
                id="surveyType"
                value={s_type}
                onChange={(e) => setType(e.target.value)}
                className="form-control"
              >
                <option value="pre_course">Pre Course</option>
                <option value="during_course">During Course</option>
                <option value="post_course">Post Course</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="surveyCategory">Survey Category:</Label>
              <Input
                type="select"
                id="surveyCategory"
                value={s_category}
                onChange={(e) => setCategory(e.target.value)}
                className="form-control"
              >
                <option value="voice_over">Voice Over</option>
                <option value="public_speaking">Public Speaking</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="surveyChapter">Chapter:</Label>
              <Input
                type="select"
                id="surveyChapter"
                value={s_chapter}
                onChange={(e) => setChapter(e.target.value)}
                className="form-control"
              >
                <option value="Chapter 1">Chapter 1</option>
                <option value="Chapter 2">Chapter 2</option>
                <option value="Chapter 3">Chapter 3</option>
                <option value="Chapter 4">Chapter 4</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="published">Published:</Label>
              <Input
                type="select"
                id="published"
                value={s_published}
                onChange={(e) => setPublished(e.target.value === "true")}
                className="form-control"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </Input>
            </FormGroup>
            {s_questions.map((q, qIndex) => (
              <div key={qIndex} className="mb-4">
                {q.isEditing ? (
                  <>
                    <FormGroup>
                      <Label>Question {qIndex + 1}:</Label>
                      <Input
                        type="text"
                        name="question"
                        value={q.question}
                        onChange={(e) => handleQuestionChange(qIndex, e)}
                        className="form-control"
                      />
                    </FormGroup>
                    {q.answers.map((a, aIndex) => (
                      <Row key={aIndex} form className="mb-2">
                        <Col md={8}>
                          <FormGroup>
                            <Label>Answer {aIndex + 1}:</Label>
                            <Input
                              type="text"
                              name="answer"
                              value={a.answer}
                              onChange={(e) =>
                                handleAnswerChange(qIndex, aIndex, e)
                              }
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup>
                            <Label>Weight:</Label>
                            <Input
                              type="number"
                              name="weight"
                              value={a.weight}
                              onChange={(e) =>
                                handleAnswerChange(qIndex, aIndex, e)
                              }
                              className="form-control"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        type="button"
                        onClick={() => addAnswer(qIndex)}
                        className="btn btn-secondary"
                      >
                        Add Answer
                      </Button>
                      <Button
                        type="button"
                        onClick={() => toggleEdit(qIndex)}
                        className="btn btn-success"
                      >
                        Save Question
                      </Button>
                      <Button
                        type="button"
                        onClick={addQuestion}
                        className="btn btn-secondary"
                      >
                        Add Question
                      </Button>
                    </div>
                  </>
                ) : (
                  <div className="question-display">
                    <p className="question-text">
                      Question {qIndex + 1}: {q.question}
                    </p>
                    <Button
                      type="button"
                      onClick={() => toggleEdit(qIndex)}
                      className="btn btn-primary"
                    >
                      Edit
                    </Button>
                  </div>
                )}
              </div>
            ))}
            {/* Add Feedback Section */}
            <FormGroup className="mt-4">
              <Label for="feedback">Feedback:</Label>
              {s_feedback.map((f, index) => (
                <Row key={index} form className="mb-2">
                  <Col md={4}>
                    <FormGroup>
                      <Label>Score:</Label>
                      <Input
                        type="number"
                        name="score"
                        value={f.score}
                        onChange={(e) => handleFeedbackChange(index, e)}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={8}>
                    <FormGroup>
                      <Label>Feedback:</Label>
                      <Input
                        type="text"
                        name="feedback"
                        value={f.feedback}
                        onChange={(e) => handleFeedbackChange(index, e)}
                        className="form-control"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              ))}
              <Button
                type="button"
                onClick={addFeedback}
                className="btn btn-secondary mb-2"
              >
                Add Feedback
              </Button>
            </FormGroup>
            {/* End Feedback Section */}
            <div style={{ marginTop: "20px", textAlign: "left" }}>
              <Button type="submit" className="btn btn-primary">
                Create Survey
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default CreateSurvey;
