import {
  GET_SURVEYS,
  GET_SURVEY_BY_ID,
  GET_RECENT_SURVEYS,
  SEARCH_SURVEYS,
  CREATE_SURVEY,
  GET_ALL_SURVEYS,
} from "endpoints/Endpoints";
import { getHttp, httpPost } from "./genericHttp.service";
import { getAuth } from "firebase/auth";

const getToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  if (user) {
    return await user.getIdToken();
  } else {
    throw new Error("User not authenticated");
  }
};

export const getSurveys = async () => {
  try {
    const token = await getToken();
    const response = await getHttp(GET_SURVEYS, true);
    return { success: true, data: response.data.surveys };
  } catch (error) {
    return { success: false, data: null, error: error };
  }
};

export const getSurveyById = async (surveyId) => {
  try {
    const token = await getToken();
    const response = await getHttp(GET_SURVEY_BY_ID(surveyId), true);
    return { success: true, data: response.data.survey };
  } catch (error) {
    return { success: false, data: null, error: error };
  }
};

export const updateSurvey = async (surveyId, updateData, uid) => {
  try {
    const token = await getToken();
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/survey/update/${surveyId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData),
      }
    );

    return await response.json();
  } catch (error) {
    console.error("Error updating survey:", error);
    return {
      success: false,
      message: "Error updating survey",
      error: error.message,
    };
  }
};

export const getRecentSurveys = async () => {
  try {
    const token = await getToken();
    const response = await getHttp(GET_RECENT_SURVEYS, true);
    return { success: true, data: response.data.surveys };
  } catch (error) {
    return { success: false, data: null, error: error };
  }
};

export const searchSurveys = async (query) => {
  try {
    const token = await getToken();
    const response = await getHttp(`${SEARCH_SURVEYS}?query=${query}`, true);
    return { success: true, data: response.data.surveys };
  } catch (error) {
    console.error("Error searching surveys:", error);
    return { success: false, data: [], error: error };
  }
};

export const createSurvey = async (data, uid) => {
  try {
    const token = await getToken();
    const response = await httpPost(CREATE_SURVEY, data, true);
    return { success: true, data: response.data };
  } catch (error) {
    return { success: false, data: null, error: error };
  }
};

export const getAllSurveys = async (user_id) => {
  try {
    const token = await getToken();
    const response = await getHttp(GET_ALL_SURVEYS(user_id), true);
    return { success: true, data: response.data.surveys };
  } catch (error) {
    return { success: false, data: null, error: error };
  }
};
