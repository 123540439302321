import React from "react";
import { Card, Col } from "reactstrap";

const NewsCard = ({ imgSrc, videoSrc, link, mediaType }) => {
  console.log("Pr: ", imgSrc);
  return (
  
  <Col style={{alignSelf: 'stretch', minHeight: '300px', maxHeight: '300px'}} md="3">
    <Card style={{marginTop: 0}} className="card-profile card-plain">
      <a href={link} target="_blank" rel="noopener noreferrer">
        {mediaType === "IMAGE" || mediaType === "CAROUSEL_ALBUM" ? (
          <img
            alt="..."
            className="img-fluid"
            src={imgSrc}
            style={styles.media}
          />
        ) : mediaType === "VIDEO" ? (
          <video  controls className="img-fluid" style={styles.media}>
            <source src={videoSrc} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : null}
      </a>
    </Card>
  </Col>
)
};

const styles = {
  media: {
    width: "100%",
    height: "300px",
    display: "block",
    borderRadius: "8px",
  },
};

export default NewsCard;
