import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllGroups, assignSurveyToGroup } from "../../service/groups";
import { getSurveys } from "../../services/survey.service";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AdminHeader from "components/Headers/AdminHeader";

const AssignQuizzes = () => {
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [quizId, setQuizId] = useState("");
  const [groups, setGroups] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchGroups();
    fetchSurveys();
  }, []);

  const fetchGroups = async () => {
    try {
      const response = await getAllGroups();
      if (response.success) {
        setGroups(response.data.groups || []);
      } else {
        console.error("Error fetching groups:", response.message);
        setGroups([]);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
      setGroups([]);
    }
  };

  const fetchSurveys = async () => {
    try {
      const recentSurveys = await getSurveys();
      setSurveys(recentSurveys.success ? recentSurveys.data : []);
    } catch (error) {
      console.error("Error fetching surveys:", error);
      setSurveys([]);
    }
  };

  const handleAssignQuiz = async () => {
    if (!selectedGroupId) {
      alert("Please select a group first");
      return;
    }
    if (!quizId) {
      alert("Please select a quiz first");
      return;
    }

    const response = await assignSurveyToGroup(selectedGroupId, quizId);
    if (response.success) {
      alert("Successfully Assigned Survey");
      navigate("/group-management");
    } else {
      alert("Failed to Assign Survey");
    }
  };

  return (
    <>
      <AppNavbar />
      <AdminHeader />
      <div style={{ padding: "20px" }}>
        <h2 style={{ margin: "30px", marginLeft: "0px" }}>Assign Surveys</h2>
        <Form className="mt-4">
          <FormGroup>
            <Label for="selectedGroupId">Select Group</Label>
            <Input
              type="select"
              name="selectedGroupId"
              id="selectedGroupId"
              value={selectedGroupId}
              onChange={(e) => setSelectedGroupId(e.target.value)}
            >
              <option value="">Select Group</option>
              {groups.map((group) => (
                <option key={group.id} value={group.id}>
                  {group.groupName}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="quizId">Select Survey</Label>
            <Input
              type="select"
              name="quizId"
              id="quizId"
              value={quizId}
              onChange={(e) => setQuizId(e.target.value)}
            >
              <option value="">Select Quiz</option>
              {surveys.map((survey) => (
                <option key={survey.s_id} value={survey.s_id}>
                  {survey.s_title}
                </option>
              ))}
            </Input>
          </FormGroup>
          <Button color="info" onClick={handleAssignQuiz}>
            Assign Survey to Group
          </Button>
        </Form>
      </div>
      <FooterBlack />
    </>
  );
};

export default AssignQuizzes;
