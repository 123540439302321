import React, { useState, useEffect } from "react";
import { Button, Container, Row } from "reactstrap";
import InstaNewsCard from "./InstaNewsCard";

const DisplayComponent = ({ mediaArray }) => {
  const [visiblePosts, setVisiblePosts] = useState([]);
  const [count, setCount] = useState(4); // Change initial count to 4

  useEffect(() => {
    setVisiblePosts(mediaArray.slice(0, count));
  }, [mediaArray, count]);

  const loadMorePosts = () => {
    setCount((prevCount) => prevCount + 4); // Change increment to 4
  };
  console.log(visiblePosts);
  return (
    <Container>
      <Row style={{ display: "flex", marginTop: "30px", rowGap: "30px" }}>
        {visiblePosts.map((post) => (
          <InstaNewsCard
            key={post.id}
            imgSrc={
              post.media_type === "IMAGE" ||
              post.media_type === "CAROUSEL_ALBUM"
                ? post.media_url
                : null
            }
            videoSrc={post.media_type === "VIDEO" ? post.media_url : null}
            link={post.permalink}
            mediaType={post.media_type}
          />
        ))}
      </Row>
      {count < mediaArray.length && (
        <div style={styles.buttonContainer}>
          <Button
            color="primary"
            onClick={loadMorePosts}
            className="learn-more-btn"
          >
            Load More
          </Button>
        </div>
      )}
    </Container>
  );
};

const styles = {
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    marginTop: "30px",
  },
};

export default DisplayComponent;
