import React from "react";
import { Container, Row, Col } from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";

function TigerBrandsPage() {
  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <div
        className="wrapper"
        style={{ backgroundColor: "white", padding: "20px" }}
      >
        <Container
          style={{
            marginTop: "20px",
            marginBottom: "20px",
            padding: "20px",
            backgroundColor: "#ffffff",
            borderRadius: "8px",
          }}
        >
          <Row className="align-items-center" style={{ minHeight: "200px" }}>
            <Col
              xs="12"
              md="7"
              style={{
                textAlign: "justify",
                paddingRight: "20px", // Ensures some space on right for larger screens
              }}
            >
              <h2
                className="title"
                style={{
                  fontWeight: "bold",
                  marginBottom: "20px",
                  textAlign: "left",
                  fontSize: "clamp(20px, 4vw, 28px)", // Responsive font size
                }}
              >
                Tiger Brands
              </h2>
              <p
                style={{
                  fontWeight: "400",
                  color: "black",
                  lineHeight: "1.6",
                  fontSize: "clamp(14px, 2.5vw, 18px)", // Responsive font size
                }}
              >
                Elevate Africa Academy hosts an annual Public Speaking
                competition at the University of Pretoria, which was launched in
                2022. Public speaking, ranked as the fourth most in-demand skill
                globally, is the focus of this competition, aiming to cultivate
                and encourage this crucial ability among young people. The event
                is supported by the Elevate Africa @UP society, offering
                students the opportunity to become members and participate in
                various public speaking-focused events, games, masterclasses,
                and activities. This collaboration directly aligns with the
                vision of Elevate Africa Academy—to amplify African voices.
              </p>
              <p
                style={{
                  fontWeight: "400",
                  color: "black",
                  lineHeight: "1.6",
                  fontSize: "clamp(14px, 2.5vw, 18px)", // Responsive font size
                }}
              >
                The competition is a platform where young people showcase their
                potential and contributions toward the advancement of our
                country and continent. Each year, the event attracts around 100
                applicants, leading to a grand finale where six speakers
                compete, and one emerges as the Top Public Speaker at the
                University of Pretoria. The competition's inaugural year in 2022
                was graced by Sadika Fakir, Integrated Media & Digital Director
                at Tiger Brands, who served as a judge. Her involvement
                highlights Tiger Brands' dedication to empowerment and support
                for youth development.
              </p>
              <p
                style={{
                  fontWeight: "400",
                  color: "black",
                  lineHeight: "1.6",
                  fontSize: "clamp(14px, 2.5vw, 18px)", // Responsive font size
                }}
              >
                This annual event has quickly become a cornerstone for fostering
                essential skills such as communication, leadership, and
                confidence among the youth. Elevate Africa Academy, through this
                competition, is investing in the future of African leadership by
                equipping young people with the tools they need to articulate
                their visions and inspire positive change across the continent.
                The platform not only prepares students for public speaking but
                also empowers them to become influential voices in their
                communities and beyond.
              </p>
            </Col>
            <Col
              xs="12"
              md="5"
              className="d-flex justify-content-center align-items-center"
              style={{ paddingTop: "20px" }} // Adds top padding for better alignment on smaller screens
            >
              <img
                src={require("assets/img/tiger-brands.jpg")}
                alt="Tiger Brands Logo"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  objectFit: "contain", // Ensures image keeps aspect ratio
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <FooterBlack />
    </>
  );
}

export default TigerBrandsPage;
