import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function VoiceOverAlumniHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/mic-background.jpg") + ")",
          backgroundPosition: "70% 10%", // Adjusted to move image more to the right
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="filter " />
        <div className="content-center">
          <Container>
            <h2>Alumni Audio CVs</h2>
          </Container>
        </div>
      </div>
    </>
  );
}

export default VoiceOverAlumniHeader;
