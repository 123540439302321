import axios from "axios";

/**
 * Sends the survey submission data to the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an empty array.
 *
 * @returns {Promise<Array>}
 */
export const submitSurvey = async (quizData) => {
  try {
    // Construct the full API URL using the environment variable

    // Log the response data for debugging purposes
    // try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/submitSurvey`,
      quizData
    );
    return response.data;
  } catch (error) {
    console.error("Error posting quiz data:", error);
  }

  // Return an empty array to signify failure to fetch data
  return [];
};
