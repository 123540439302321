import React from "react";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import { Container, Row, Col, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import ElmaImage from "assets/img/champions.jpeg";
import JciImage from "assets/img/championships.jpeg";
import "../../Styling/App.css";

const YouthOwnedBrand = () => {
  return (
    <div>
      <AppNavbar />
      <CleanHeader />
      <Container className="px-4 py-5">
        <h2
          style={{
            color: "black",
            fontWeight: "bold",
            marginBottom: "clamp(20px, 5vw, 45px)",
            marginTop: "0px",
            fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
            textAlign: "center",
          }}
        >
          2022 Youth Owned Education Brand Award
        </h2>
        <Row>
          <Col
            md="6"
            style={{
              color: "black",
              marginBottom: "clamp(15px, 2vw, 30px)",
              display: "flex",
              flexDirection: "column",
              gap: "clamp(15px, 2vw, 25px)",
            }}
          >
            <div>
              <p
                className="text-dark"
                style={{ fontSize: "clamp(1rem, 4vw, 1.25rem)" }}
              >
                Established in 2022, the top 16 youth-owned brands awards are a
                platform that is aimed at recognizing and celebrating youth
                excellence. The top 16 youth-owned brands awards acknowledges
                that young people are not only contributing to the economy of
                South Africa, but the overall image of the country.
              </p>
              <p
                className="text-dark"
                style={{ fontSize: "clamp(1rem, 4vw, 1.25rem)" }}
              >
                In a time where the unemployment rate is at an all-time low,
                these awards celebrate brands that create jobs in the process of
                honing their craft. The awards celebrate youth-owned brands in
                16 categories, namely; Agriculture, Apparel, Creative and Arts,
                Education and Literature, Footwear and Accessories, Food and
                Beverage, Hair and Beauty, Health and Wellness, Homeware,
                Manufacturing, Media, NPO's and Charities, Personalities and
                Influencers, Services, Technology, Travel and Tourism. Elevate
                Africa Academy won the inaugural award for the top youth owned
                education brand. The Top 16 Youth-Owned Brands Awards are the
                GOLD STANDARD OF YOUTH EXCELLENCE.
              </p>
            </div>
          </Col>

          <Col
            md="6"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "clamp(20px, 5vw, 30px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                height: "100%",
              }}
            >
              <div style={{ flex: 2 }}>
                <img
                  src={ElmaImage}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "clamp(300px, 40vw, 400px)",
                    objectFit: "contain",
                  }}
                  alt="Elma Image"
                />
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={JciImage}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "clamp(300px, 40vw, 400px)",
                    objectFit: "contain",
                  }}
                  alt="JCI Image"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* Back Button */}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          marginTop: "0",
          marginBottom: "2rem",
          paddingLeft: "15px",
        }}
      >
        <Button
          color="primary"
          tag={NavLink}
          to="/about-us"
          style={{
            width: "clamp(120px, 15vw, 150px)",
            textAlign: "center",
            fontSize: "clamp(1rem, 3vw, 1.1rem)",
          }}
          className="learn-more-btn"
        >
          Back
        </Button>
      </div>

      <FooterBlack />
    </div>
  );
};

export default YouthOwnedBrand;
