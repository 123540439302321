import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  getAuth,
} from "firebase/auth";
import { auth } from "firebase-config/firebase-config";
import { loginCreate } from "./user.service";

export const createUser = async (email, password) => {
  const response = await createUserWithEmailAndPassword(auth, email, password)
    .then((userCred) => {
      return { success: true, error: null, data: userCred.user };
    })
    .catch((error) => {
      console.error(error);
      return { success: false, error: error };
    });

  return response;
};

export const loginWithEmailPassword = async (email, password) => {
  const response = await signInWithEmailAndPassword(auth, email, password)
    .then((userCred) => {
      return { success: true, error: null, data: userCred.user };
    })
    .catch((error) => {
      return { success: false, error: error };
    });

  return response;
};

export const loginWithGooglePopup = async () => {
  const provider = new GoogleAuthProvider();
  const response = await signInWithPopup(auth, provider)
    .then((userCred) => {
      return { success: true, error: null, data: userCred.user };
    })
    .catch((error) => {
      console.error(error);
      return { success: false, error: error };
    });

  return response;
};

export const getUserToken = async () => {
  const auth = getAuth();
  const user = auth.currentUser;
  return user ? await user.getIdToken() : null;
};

export default {
  loginWithEmailPassword,
  loginWithEmailPassword,
  loginWithGooglePopup,
  loginCreate,
};
