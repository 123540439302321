import emailjs from "@emailjs/browser";

const sendEmail = (formData) => {
  const serviceID = "service_mxfnzpk";
  const templateID = "template_kgn3met";
  const publicKey = "OGOJFuK8ZXgEBOO2f";

  const typeLabels = {
    publicSpeakingPrices: "Public Speaking Prices",
    publicSpeakingTrainingDates: "Public Speaking Training Dates",
    voiceOverActingPrices: "Voice Over Acting Prices",
    voiceOverActingTrainingDates: "Voice Over Acting Training Dates",
    other: "Other",
  };

  // Convert the inquiryType object into an array of readable labels
  const inquiryTypes = Object.entries(formData.inquiryType)
    .filter(([key, value]) => value) // Filter out types that are false
    .map(([key]) => typeLabels[key]); // Map keys to their readable labels

  // Join these labels into a comma-separated string
  const inquiryTypeString = inquiryTypes.join(", ");

  const templateParams = {
    to_name: "elevate africa academy",
    from_name: formData.firstName + " " + formData.lastName,
    from_email: formData.email,
    from_phone: formData.phoneNumber,
    message_html: formData.message,
    inquiryType: inquiryTypeString,
  };

  return emailjs.send(serviceID, templateID, templateParams, publicKey);
};

export default sendEmail;
