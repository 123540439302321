import axios from "axios";
import { getUserToken } from "./auth.service";

//const baseUrl = "https://dev-dot-elevate-africa-427707.ew.r.appspot.com/api";
const baseUrl = "https://dev-dot-elevate-africa-427707.ew.r.appspot.com/api";
//const baseUrl = "http://localhost:3004";
export const getHttp = async (url, token = false) => {
  if (token) {
    return axios.get(`${baseUrl}/${url}`, await buildHeaderToken());
  }
  return axios.get(`${baseUrl}/${url}`);
};

export const httpPut = async (path, data, token = false) => {
  if (token) {
    return axios.put(`${baseUrl}/${path}`, data, await buildHeaderToken());
  }
  return axios.put(`${baseUrl}/${path}`, data);
};

export const httpDelete = async (path, token = false) => {
  if (token) {
    return axios.delete(`${baseUrl}/${path}`, await buildHeaderToken());
  }
  return axios.delete(`${baseUrl}/${path}`);
};

export const httpPost = async (path, newObj, token = false) => {
  if (token) {
    return axios.post(`${baseUrl}/${path}`, newObj, await buildHeaderToken());
  }
  return axios.post(`${baseUrl}/${path}`, newObj);
};

const buildHeaderToken = async () => {
  const token = await getUserToken();

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};
