import React from "react";
import { Button } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const FeedbackScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const quiz = location.state?.quiz;
  const feedback = quiz.s_feedback
    ? quiz.s_feedback
        .slice()
        .sort((a, b) => b.score - a.score)
        .find((f) => f.score <= quiz.totalScore)
    : null;
  console.log(feedback);

  const handleExit = () => {
    navigate(`/home`, {});
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100vh",
        padding: "20px",
        backgroundColor: "#f9f9f9",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "800px",
          textAlign: "center",
          background: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          padding: "20px",
        }}
      >
        <h1>Quiz Feedback</h1>
        {feedback ? (
          <>
            <p>
              <strong>Score:</strong> {feedback.score}
            </p>
            <p>
              <strong>Feedback:</strong> {feedback.feedback}
            </p>
          </>
        ) : (
          <p>No feedback available.</p>
        )}
      </div>
      <Button
        color="secondary"
        onClick={handleExit}
        style={{
          marginTop: "20px",
          width: "150px",
          height: "50px",
          fontSize: "16px",
          borderRadius: "25px",
        }}
      >
        Exit
      </Button>
    </div>
  );
};

export default FeedbackScreen;
