import React, { useState } from "react";
import * as styles from "./login.css";
// reactstrap components
import {
  Button,
  Card,
  Form,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
  Alert,
} from "reactstrap";

// core components
import authService from "services/auth.service";
import { useNavigate } from "react-router-dom";

function RegisterPage() {
  const authService = require("../services/auth.service");
  const userService = require("../services/user.service");
  const navigate = useNavigate();
  document.documentElement.classList.remove("nav-open");
  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  });

  const gotToLogin = async () => {
    navigate("/login-page");
  };

  const handleRegister = async () => {
    if (!userData.fullName || !userData.email || !userData.password) {
      setAlertMessage("All fields are required.");
      setAlertVisible(true);
      return;
    }

    const response = await authService.createUser(
      userData.email,
      userData.password
    );

    if (response.success) {
      const loginResponse = await userService.loginCreate(
        response.data.uid,
        response.data.email,
        userData.fullName
      );

      if (loginResponse.success) {
        navigate("/login-page");
      } else {
        // Handle unsuccessful login creation
      }
    } else {
      if (response.error.code === "auth/email-already-in-use") {
        console.error("Email is already in use");
      }
      // Handle other errors
    }
  };

  const handleGoogleSignIn = async () => {
    const response = await authService.loginWithGooglePopup();

    if (response.success) {
      const googleUser = response.data;
      const loginResponse = await userService.loginCreate(
        googleUser.uid,
        googleUser.email,
        googleUser.displayName // Use the name provided by Google
      );

      if (loginResponse.success) {
        navigate("/home");
        alert("You have registered in successfully!");
      } else {
        setAlertMessage("Error completing Google Sign-In. Please try again.");
        setAlertVisible(true);
      }
    } else {
      console.error(response.error);
      setAlertMessage("Google Sign-In failed. Please try again.");
      setAlertVisible(true);
    }
  };

  return (
    <>
      <div className="wrapper">
        <div
          className="page-header"
          style={{
            backgroundImage:
              "url(" + require("assets/img/login-back-1.jpg") + ")",
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-register">
                  <h3
                    style={{
                      marginTop: "0px",
                      textAlign: "center",
                      fontWeight: "bold",
                      marginBottom: "10px",
                    }}
                  >
                    REGISTER
                  </h3>
                  {alertVisible && (
                    <Alert color="danger" toggle={() => setAlertVisible(false)}>
                      {alertMessage}
                    </Alert>
                  )}
                  <FormGroup className="label-floating">
                    <label>Name</label>
                    <Input
                      placeholder="Name"
                      type="text"
                      required
                      onChange={(e) =>
                        setUserData((prev) => ({
                          ...prev,
                          fullName: e.target.value,
                        }))
                      }
                    />
                    <label>Email</label>
                    <Input
                      placeholder="Email"
                      type="email"
                      required
                      onChange={(e) =>
                        setUserData((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                    <label>Password</label>
                    <Input
                      placeholder="Password"
                      type="password"
                      required
                      onChange={(e) =>
                        setUserData((prev) => ({
                          ...prev,
                          password: e.target.value,
                        }))
                      }
                    />
                    <Button onClick={handleRegister} block color="info">
                      Register
                    </Button>

                    <Button onClick={handleGoogleSignIn} block color="info">
                      Register with Google
                    </Button>
                    <p
                      className="card-description"
                      style={{ textAlign: "center", color: "black" }}
                    >
                      Already have an account?{" "}
                      <span onClick={gotToLogin} className="sign-up-link">
                        Login
                      </span>
                    </p>
                  </FormGroup>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default RegisterPage;
