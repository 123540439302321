import axios from "axios";

/**
 * Fetches the collection of alumni data from the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an empty array.
 *
 * @returns {Promise<Array>} A promise that resolves to the alumni data array.
 */
export const getAlumnis = async () => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/alumni/`
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return response.data; // Adjust based on the expected response structure
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching alumni data:", error);

    // Return an empty array to signify failure to fetch data
    return [];
  }
};

export const getPublicSpeakingCollection = async () => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/alumni/publicSpeaking`
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return response.data; // Adjust based on the expected response structure
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching public speaking alumni data:", error);

    // Return an empty array to signify failure to fetch data
    return [];
  }
};
