import axios from "axios";

export const addCertificate = async (certificateData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/users/addCertificate`,
      //`${process.env.REACT_APP_API_URL}/api/users/addCertificate`,

      certificateData
    );
    return response.data;
  } catch (error) {
    console.error("Error adding certificate:", error);
    return { success: false, message: "Error adding certificate" };
  }
};

// Fetch certificates for a user
export const getUserCertificates = async (userId) => {
  try {
    const response = await axios.get(
      // `${process.env.REACT_APP_API_URL}/api/users/getUserCertificates/${userId}`
      `${process.env.REACT_APP_API_URL}/users/getUserCertificates/${userId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching certificates:", error);
    return { success: false, message: "Error fetching certificates" };
  }
};
