import React from "react";
import { Row, Col, Button } from "reactstrap";

const SponsorshipSection = () => (
  <Row style={{ marginBottom: "30px" }} className="justify-content-center">
    {/* Left Column */}
    <Col xs="12" md="6" className="mb-4 mb-md-0">
      <h2 style={{ fontSize: "clamp(20px, 4vw, 30px)" }} className="title">
        2022 Mrs Universe Africa Sponsor
      </h2>
      <p>
        African Beauty International Organization is a home for beauty with a
        purpose, a mission, and a cause. Elevate Africa Academy sponsors the
        contestants with the oratory skills they need to stand out and take home
        this prestigious crown.
      </p>
      <Button
        color="primary"
        href="https://www.instagram.com/mrsuniverseafrica/"
        target="_blank"
        style={{ margin: "10px" }}
        className="learn-more-btn"
      >
        Read More
      </Button>
    </Col>

    {/* Right Column (Image) */}
    <Col xs="12" md="6">
      <img
        alt="Mrs Universe Africa"
        className="img-fluid"
        src={require("assets/img/demo/missuniverse.png")}
        style={{
          borderRadius: "10px",
          width: "100%", // Ensures the image takes full width of its container
          height: "auto", // Keeps the image's aspect ratio
        }}
      />
    </Col>
  </Row>
);

export default SponsorshipSection;
