import { getAuth } from "firebase/auth";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, adminRoute = false, redirectUrl }) => {
  const auth = getAuth();
  const [isAuth, setIsAuth] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const locationUrl = `/login-page?redirectUrl=${redirectUrl}`;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      if (currentUser) {
        const idTokenResult = await currentUser.getIdTokenResult();
        setIsAuth(true);
        setIsAdmin(idTokenResult.claims.admin || false);
      } else {
        setIsAuth(false);
        setIsAdmin(false);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAuth) {
    return <Navigate to={locationUrl} replace />;
  }

  if (adminRoute && !isAdmin) {
    return <Navigate to="/not-authorized" replace />;
  }

  return children;
};

export default ProtectedRoute;
