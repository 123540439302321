import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";

const BannerHeader = ({
  backgroundImage,
  title,
  description,
  showLogo,
  logoSrc,
}) => {
  const pageHeader = useRef(null);

  useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        if (pageHeader.current) {
          pageHeader.current.style.transform = `translate3d(0, ${windowScrollTop}px, 0)`;
        }
      };
      window.addEventListener("scroll", updateScroll);
      return () => {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, []);

  return (
    <div
      className="page-header page-header-small"
      ref={pageHeader}
      style={{
        backgroundImage:
          backgroundImage ??
          "url(" + require("assets/img/sections/mic-background.jpg") + ")",
        position: "relative",
        height: "200px", // Adjusted height
        backgroundSize: "cover",
        backgroundPosition: "70% 10%", // Adjusted to move image more to the right
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="filter" />
      <div className="content-center">
        <Container>
          <Row>
            {showLogo && (
              <Col md="4">
                <img
                  alt="Logo"
                  className="img-responsive img-no-padding"
                  src={require("assets/img/ElevateAfrica_White-2.png")}
                  height={200} // Adjusted height of the image
                />
              </Col>
            )}
            <Col md={showLogo ? "8" : "12"}>
              <div className="motto">
                <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                  <h2 style={{ fontWeight: "bold" }}>{title}</h2>
                </div>
                <h4 className="description">
                  <small style={{ color: "white" }}>{description}</small>
                </h4>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

BannerHeader.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showLogo: PropTypes.bool,
  logoSrc: PropTypes.string,
};

export default BannerHeader;
