import { createContext, useState, useContext } from "react";

export const GameStateContext = createContext();

export const GameStateProvider = ({ children }) => {
  const [gameState, setGameState] = useState("menu");
  const [score, setScore] = useState(0);
  const [userName, setUserName] = useState("");

  return (
    <GameStateContext.Provider
      value={{
        gameState,
        setGameState,
        score,
        setScore,
        userName,
        setUserName,
      }}
    >
      {children}
    </GameStateContext.Provider>
  );
};
