import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function AboutUsHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundImage:
            "url(" + require("assets/img/sections/mic-background.jpg") + ")",

          backgroundSize: "cover",
          backgroundPosition: "center 100% 10%", // Adjust to position the microphone in view
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="filter " />
        <div className="content-center">
          <Container>
            <h1>Get in touch with us</h1>
            <h3>
              For inquiries about our services or any questions, please fill the
              form below
            </h3>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUsHeader;
