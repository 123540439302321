import React, { useEffect, useState } from "react";
import { getProducts } from "../services/product.service";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AppNavbar from "components/Navbars/AppNavbar";
import Banner from "components/banner";
import CardComponent from "components/Video/CardComponent";
import { Container, Row, Col } from "reactstrap";
import { initializePayment } from "../services/booking.service";

const BookNow = () => {
  const [products, setProducts] = useState([]);
  const auth = getAuth();
  const navigate = useNavigate();
  const cards = [];

  useEffect(() => {
    handleGetProducts();
  }, []);

  const handleGetProducts = async () => {
    const response = await getProducts();
    if (response.success) {
      let data = [...response.data];
      data = data.sort((a, b) => a.p_order - b.p_order);
      setProducts([...data]);
    }
  };

  const handleBooking = async (p_id) => {
    if (!auth.currentUser) {
      navigate("/login-page", {
        state: {
          from: "/book-now",
          packageId: p_id,
        },
      });
      return;
    }

    // Proceed with booking if logged in
    const bookingData = {
      packageId: p_id,
      userId: auth.currentUser.uid,
    };

    const response = await initializePayment(bookingData);
    if (response.success) {
      window.open(response.data.url, "_self");
    } else {
      // Handle booking error
      console.error("Booking failed");
    }
  };

  return (
    <>
      <AppNavbar />
      <Banner
        title="Book a course"
        description=""
        showLogo={true}
        logoSrc="assets/img/ElevateAfrica_White-2.png"
        cards={cards}
        cardsAlignment="center"
      />
      <div className="section text-center book-section py-5">
        <Container>
          <Row className="coloured-cards d-flex justify-content-center">
            {products &&
              products.map((item, index) => (
                <Col
                  md="4" // Default to 4 columns on medium screens
                  sm="6" // 2 columns on small screens
                  xs="12" // Full width on extra small screens
                  key={index}
                  className="mb-4 custom-col" // Custom class to adjust layout on mobile
                >
                  <CardComponent
                    videoSrc={item.p_video_url}
                    videoWidth={"100%"} // Make video responsive
                    videoHeight={"auto"} // Maintain aspect ratio
                    price={"R " + item.p_price}
                    name={item.p_title}
                    onBookPressed={() => handleBooking(item.p_id)}
                  />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default BookNow;
