import React from "react";

function AdminHeader() {
  return (
    <>
      <div
        className="page-header page-header-small"
        style={{
          position: "relative",
          backgroundImage: "url(" + require("assets/img/admin.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "400px",
        }}
      >
        <div
          className="filter"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(10,88,136,0.38)",
          }}
        />
        <div className="content-center">
          {/* Add any content you want to center here */}
        </div>
      </div>
    </>
  );
}

export default AdminHeader;
