import React from "react";
import { Button, Card, CardBody } from "reactstrap";
import VideoComponent from "./videoComponent";

const CardComponent = ({
  videoSrc,
  videoWidth,
  videoHeight,
  name,
  price,
  email,
  image,
  width,
  height,
  onBookPressed,
  showBookButton = true,
}) => {
  return (
    <Card className="card-blog mb-4" style={{ marginTop: "0px" }}>
      <CardBody className="p-0">
        {videoSrc && (
          <VideoComponent
            src={videoSrc}
            width={videoWidth}
            height={videoHeight}
          />
        )}
        {image && <img src={image} width={width} height={height} alt={name} />}
        <div className="text-center mt-3" style={{ marginBottom: "20px" }}>
          <h6 className="card-category text-info">{name}</h6>
          {price && <h5 className="card-category text-info">{price}</h5>}
          {showBookButton && (
            <Button
              color="info"
              className="learn-more-btn"
              onClick={() =>
                onBookPressed
                  ? onBookPressed()
                  : window.open(
                      `mailto:${email}?subject=Booking Inquiry`,
                      "_blank"
                    )
              }
            >
              Book
            </Button>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default CardComponent;
