import axios from "axios";

/**
 * Fetches the collection of products data from the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an empty array.
 *
 * @returns {Promise<Array>} A promise that resolves to the products data array.
 */
export const getProducts = async () => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/`
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return response.data;
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching products data:", error);

    // Return an empty array to signify failure to fetch data
    return [];
  }
};

/**
 * Fetches a single product by ID from the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns null.
 *
 * @param {number} productId - The ID of the product to fetch.
 * @returns {Promise<Object>} A promise that resolves to the product object.
 */
export const getProductById = async (productId) => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/${productId}`
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return response.data;
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching product by ID:", error);

    // Return null to signify failure to fetch data
    return null;
  }
};

/**
 * Fetches the collection of featured products data from the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an empty array.
 *
 * @returns {Promise<Array>} A promise that resolves to the featured products data array.
 */
export const getFeaturedProducts = async () => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/products/featured`
    );

    // Log the response data for debugging purposes
    // Return the data received from the server
    return response.data;
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching featured products data:", error);

    // Return an empty array to signify failure to fetch data
    return [];
  }
};
