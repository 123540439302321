import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  CardText,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom"; // Assuming you're using React Router
import corporateclients from "assets/img/Untitled design(2).svg";
import InstaNewsSection from "./LandingPageSections/InstaNewsSection";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import TeamDisplay from "views/TeamDisplay";
import Testimonials from "components/Testimonials";
import { getProducts } from "../service/products";
import HeaderWithCarousel from "components/Headers/HeaderWithCarousel";
import ScrollingFlags from "./LandingPageSections/Flag";
import "../Styling/App.css";
import "../Styling/LandingPage.css";

function LandingPage() {
  const navigate = useNavigate();
  const accessToken =
    "IGQWROcDIwR1BHeWJEcnRrQjFncDhmUkFWSGVHdkZAINnplMGY1c0t4d2kteGxsOVZANVWV0MFZANNlJpME5DaVFVNV9QcXktUUdScVNMY1hQMWNJRVpLRmJTcGVwZAzJkbV9yeTE2S2dhRXk2eXJ6OG1jdFlROUFKbkUZD";

  const [media, setMedia] = useState([]);
  const [products, setProducts] = useState([]);
  const [teamLoading, setTeamLoading] = useState(true);
  const [productsLoading, setProductsLoading] = useState(true);
  const [newsLoading, setNewsLoading] = useState(true);

  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    fetchProducts();
    fetchInstagramMedia();

    // Simulate team data loading, set loading to false after the component mounts
    setTimeout(() => {
      setTeamLoading(false);
    }, 1500); // Adjust this timeout to simulate loading duration

    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  }, []);

  const fetchInstagramMedia = async () => {
    try {
      const response = await fetch(
        `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${accessToken}`
      );
      const data = await response.json();
      console.log("Data: ", data);
      setMedia(data.data);
      setNewsLoading(false); // Set news loading to false when data is fetched
    } catch (error) {
      console.error("Error fetching Instagram media:", error);
      setNewsLoading(false); // Ensure spinner stops even if there's an error
    }
  };

  const fetchProducts = async () => {
    const response = await getProducts();
    if (response.success && response.data.length > 0) {
      let data = [...response.data];
      data = data.sort((a, b) => a.p_order - b.p_order);
      setProducts([...data]);
      setProductsLoading(false); // Set products loading to false when data is fetched
    }
  };
  const handleButtonClick = (url) => {
    navigate(url);
  };
  const { ref, inView } = useInView({
    triggerOnce: true, // Animation will only trigger once
    threshold: 0.5, // Trigger when 50% of the section is in view
  });

  return (
    <>
      <AppNavbar />
      <HeaderWithCarousel />

      <div className="wrapper">
        {/* Landing Section */}
        <div className="section text-center landing-section">
          <Container
            className="custom-container"
            style={{ paddingBottom: "0px" }}
          >
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2
                  className="title"
                  style={{
                    fontWeight: "bold",
                    marginTop: "60px",
                    fontSize: "clamp(18px, 5vw, 45px)",
                  }}
                >
                  WELCOME TO ELEVATE AFRICA ACADEMY
                </h2>
                <p
                  className="card-description"
                  style={{
                    fontWeight: "400",
                    fontSize: "clamp(1rem, 3vw, 18px)",
                  }}
                >
                  Welcome to the world of eloquence and expression! Our
                  comprehensive courses are designed to unlock your potential,
                  giving you the tools to captivate audiences and master the art
                  of communication. Whether you’re aspiring to become a public
                  speaking pro or a voice-over artist, our expert-led training
                  will guide you every step of the way. Elevate your voice,
                  empower your message, and embark on a journey to speaking
                  success with us. Start transforming your vocal presence today!
                </p>
              </Col>
            </Row>
            <br />
          </Container>
        </div>

        {/* Experienced Team Section */}
        <div
          className="section text-center landing-section"
          style={{ paddingTop: "0px", paddingBottom: "10px" }}
        >
          <div
            className="section"
            style={{
              backgroundColor: "#f5f6fa",
              paddingBottom: "40px",
              paddingTop: "0px",
            }}
          >
            <h2
              className="title"
              style={{
                fontWeight: "bold",
                marginTop: "0px",
                paddingTop: "40px",
                fontSize: "clamp(18px, 5vw, 45px)",

                marginBottom: "40px",
              }}
            >
              EXPERIENCED TEAM
            </h2>

            {teamLoading ? (
              <div className="text-center">
                <Spinner color="info" />
              </div>
            ) : (
              <TeamDisplay />
            )}
          </div>

          {/* What to Expect Section */}
          <div className="section" style={{ paddingTop: "0px" }}>
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2
                    className="title"
                    style={{
                      fontWeight: "bold",
                      color: "black",
                      marginTop: "40px",
                      marginBottom: "10px",
                      fontSize: "clamp(24px, 5vw, 36px)",
                    }}
                  >
                    WHAT TO EXPECT
                  </h2>
                </Col>
              </Row>

              {productsLoading ? (
                <div className="text-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                products &&
                products.length > 0 &&
                products.map((product, index) => (
                  <Row
                    key={index}
                    style={{
                      backgroundColor: "8A95A5",
                      marginBottom: "0px",
                      marginTop: "20px",
                      borderRadius: "8px",
                    }}
                  >
                    {/* Mobile-only title */}
                    <Col xs="12" className="d-md-none">
                      <h3
                        style={{
                          fontWeight: "bold",
                          color: "black",
                          textTransform: "uppercase",
                          fontSize: "clamp(18px, 4vw, 1.5em)",
                          marginBottom: "20px",
                          marginTop: "20px",
                          textAlign: "left",
                          paddingLeft: "15px",
                        }}
                        className="card-category"
                      >
                        {product.p_title}
                      </h3>
                    </Col>

                    <Col className="mr-auto" md="6">
                      <div
                        className="iframe-container"
                        style={{
                          position: "relative",
                          paddingBottom: "56.25%",
                          marginTop: { xs: "0", md: "80px" },
                          height: "0",
                          overflow: "hidden",
                        }}
                      >
                        <iframe
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            border: "none",
                          }}
                          src={product.p_video_url}
                          title={product.p_title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen
                        ></iframe>
                      </div>
                    </Col>
                    <Col className="mr-auto" md="5">
                      <Card className="card-plain" style={{ border: "none" }}>
                        <CardBody style={{ paddingTop: "0px" }}>
                          <div className="list-wrapper">
                            {/* Desktop-only title */}
                            <h3
                              style={{
                                fontWeight: "bold",
                                color: "black",
                                textTransform: "uppercase",
                                marginTop: "0px",
                                fontSize: "1.5em",
                                marginBottom: "30px",
                                textAlign: "left",
                                display: "none", // Hide by default
                                "@media (min-width: 768px)": {
                                  display: "block", // Show on desktop
                                },
                              }}
                              className="card-category d-none d-md-block"
                            >
                              {product.p_title}
                            </h3>
                            <ol
                              role="list"
                              className="ol"
                              style={{ marginBottom: "30px" }}
                            >
                              {product.p_description &&
                                product.p_description.map(
                                  (desc, desc_index) => (
                                    <li key={desc_index} className="li">
                                      {desc}
                                    </li>
                                  )
                                )}
                            </ol>
                          </div>
                          <div
                            style={{
                              color: "black !important",
                              textAlign: "left",
                            }}
                          >
                            {product.p_buttons &&
                              product.p_buttons.length > 0 &&
                              product.p_buttons.map((btn, btn_index) => (
                                <Button
                                  key={btn_index}
                                  color="primary"
                                  type="button"
                                  className="learn-more-btn"
                                  onClick={() => handleButtonClick(btn.url)}
                                  style={{
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  {btn.title}
                                </Button>
                              ))}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ))
              )}
            </Container>
          </div>
        </div>

        <div className="testimonials-wrapper" style={{ marginTop: "0px" }}>
          <Testimonials />
        </div>
        {/* We numbers and countries */}
        <div
          className="section section-testimonials"
          style={{ paddingTop: "30px" }}
        >
          <div className="our-clients" style={{ marginTop: "-30px" }}>
            <Container style={{ marginBottom: "50px" }}>
              <ScrollingFlags />
            </Container>
          </div>
        </div>

        {/* Corporate Clients Section */}
        <div
          className="project-4 section"
          style={{ marginBottom: "40px", paddingTop: "10px" }}
        >
          <Container className="text-center">
            <Col className="ml-auto mr-auto" md="8">
              <h2
                style={{
                  fontWeight: "bold",
                  color: "black",
                  marginTop: "20px",
                  fontSize: "clamp(18px, 7.5vw, 45px)",
                  marginBottom: "20px",
                }}
              >
                CORPORATE CLIENTS
              </h2>
            </Col>
            <div
              style={{
                width: "100%",
                overflow: "hidden",
                paddingBottom: "50px",
                maxWidth: "1200px",
                margin: "0 auto",
              }}
            >
              <img
                src={corporateclients}
                alt="Our Values"
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  height: "auto",
                  display: "block",
                  margin: "0 auto",
                  padding: "0 clamp(10px, 3vw, 30px)",
                  objectFit: "contain",
                }}
              />
            </div>
          </Container>

          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2
                  style={{
                    fontWeight: "bold",
                    color: "black",
                    marginTop: "30px",
                    marginTop: "30px",
                    marginBottom: "20px",
                    fontSize: "clamp(18px, 7.5vw, 45px)",
                  }}
                >
                  PARTNERSHIPS
                </h2>
              </Col>
            </Row>
            <div className="space-top" style={{ marginTop: "30px" }} />
            <Row>
              <Col md="6">
                <Card className="h-100">
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/tiger-brands.jpg")}
                    alt="Tiger Brands"
                    style={{ height: "200px", objectFit: "contain" }}
                  />
                  <CardBody className="d-flex flex-column align-items-center">
                    <CardTitle
                      tag="h5"
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Tiger Brands
                    </CardTitle>
                    <Link to="/Tiger-Brands">
                      <Button color="primary" className="learn-more-btn">
                        Learn More
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card className="h-100">
                  <CardImg
                    top
                    width="100%"
                    src={require("assets/img/brands-south-africa.png")}
                    alt="Brands South Africa"
                    style={{ height: "200px", objectFit: "contain" }}
                  />
                  <CardBody className="d-flex flex-column align-items-center">
                    <CardTitle
                      tag="h5"
                      style={{
                        fontWeight: "bold",
                        color: "black",
                        fontSize: "16px",
                        marginBottom: "15px",
                      }}
                    >
                      Brands South Africa
                    </CardTitle>
                    <Link to="/Brands-Southafrica">
                      <Button color="primary" className="learn-more-btn">
                        Learn More
                      </Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <div
            className="projects-3"
            id="projects-3"
            style={{ paddingTop: "75px" }}
          >
            {media && media.length > 0 && (
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2
                      className="title"
                      style={{
                        margin: "0px",
                        fontSize: "clamp(18px, 7.5vw, 45px)",
                      }}
                    >
                      NEWS & UPDATES
                    </h2>
                  </Col>
                </Row>

                {newsLoading ? (
                  <div className="text-center">
                    <Spinner color="primary" />
                  </div>
                ) : (
                  <InstaNewsSection
                    mediaArray={media && media.length > 0 ? media : []}
                  />
                )}
              </Container>
            )}
          </div>
        </div>
      </div>

      <FooterBlack />
    </>
  );
}

export default LandingPage;
