export const GET_USER_BY_ID = (user_id) => `users/getUser/${user_id}`;
export const CREATE_UPDATE_USER = `users/createUpdateUser`;
export const POST_INITIALIZE_PAYMNET = "booking/initializePayment";
export const POST_INITIALIZE_OZOW_PAYMENT = "/booking/initializePayment";
export const GET_PRODUCTS = "/products";

export const GET_SURVEYS = "survey/allSurveysWithoutFilter";
export const GET_SURVEY_BY_ID = (surveyId) =>
  `survey/getSurveyById/${surveyId}`;
export const UPDATE_SURVEY = (surveyId) => `survey/update/${surveyId}`;
export const CREATE_SURVEY = "survey/create";
export const GET_RECENT_SURVEYS = "survey/recent";
export const SEARCH_SURVEYS = "/survey/search";
export const GET_COMPLETED_SURVEYS = (user_id) =>
  `users/getCompletedSurveys/${user_id}`;
export const GET_ALL_SURVEYS = (user_id) => `users/fetchUserSurveys/${user_id}`;
export const GET_ALL_USERS = "users/getAllUsers";

export const GET_TEAM = `https://dev-dot-elevate-africa-427707.ew.r.appspot.com/team/getTeamDetails`;
export const GET_TEAM_MEMBER_BY_ID = (id) =>
  `https://dev-dot-elevate-africa-427707.ew.r.appspot.com/team/getTeamMemberById/${id}`;
export const CREATE_TEAM_MEMBER = `https://dev-dot-elevate-africa-427707.ew.r.appspot.com/team/addTeamMember`;
export const UPDATE_TEAM_MEMBER_BY_ID = (id) =>
  `https://dev-dot-elevate-africa-427707.ew.r.appspot.com/team/updateTeamMember/${id}`;
export const DELETE_TEAM_MEMBER = (id) =>
  `https://dev-dot-elevate-africa-427707.ew.r.appspot.com/team/deleteTeamMember/${id}`;
