import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Spinner, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AdminHeader from "components/Headers/AdminHeader";
import { getAllGroups } from "../../service/groups";

const GroupManagement = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = async () => {
    setLoading(true);
    try {
      const response = await getAllGroups();
      if (response.success) {
        setGroups(response.data.groups || []);
        setFilteredGroups(response.data.groups.slice(0, 3) || []);
      } else {
        console.error("Error fetching groups:", response.message);
        setGroups([]);
        setFilteredGroups([]);
      }
    } catch (error) {
      console.error("Error fetching groups:", error);
      setGroups([]);
      setFilteredGroups([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setFilteredGroups(groups.slice(0, 3));
    } else {
      const searchResults = groups.filter((group) =>
        group.groupName.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilteredGroups(searchResults);
    }
  };

  const navigateToCreateGroup = () => {
    navigate("/create-group");
  };

  const navigateToAssignQuizzes = () => {
    navigate("/assign-quizzes");
  };

  const navigateToEditGroup = (groupId) => {
    navigate(`/edit-group/${groupId}`);
  };
  const navigateToSendCertificates = (groupId) => {
    navigate(`/send-certificates/${groupId}`);
  };
  return (
    <>
      <AppNavbar />
      <AdminHeader />
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2 style={{ margin: "30px", marginLeft: "0px" }}>Group Management</h2>
        <Input
          type="text"
          placeholder="Search groups..."
          value={searchQuery}
          onChange={handleSearch}
          style={{ marginBottom: "20px", maxWidth: "auto", margin: "0 auto" }}
        />
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Spinner color="info" />
          </div>
        ) : filteredGroups.length === 0 ? (
          <div style={{ textAlign: "center", margin: "20px 0" }}>
            No groups available
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "20px",
              justifyContent: "flex-start",
              marginTop: "30px",
              marginBottom: "50px",
            }}
          >
            {filteredGroups.map((group) => (
              <Card
                key={group.id}
                style={{
                  flex: "1 1 calc(33.333% - 20px)",
                  boxSizing: "border-box",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "30px",
                }}
              >
                <CardBody
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CardTitle
                    tag="h5"
                    style={{ textAlign: "center", marginBottom: "20px" }}
                  >
                    {group.groupName}
                  </CardTitle>
                  <Button
                    color="info"
                    onClick={() => navigateToEditGroup(group.id)}
                  >
                    Edit Group
                  </Button>
                  <Button
                    color="info"
                    onClick={() => navigateToSendCertificates(group.id)}
                  >
                    Send Certificates
                  </Button>
                </CardBody>
              </Card>
            ))}
          </div>
        )}

        <Button
          color="info"
          style={{ margin: "10px", padding: "10px 20px", fontSize: "16px" }}
          onClick={navigateToCreateGroup}
        >
          Create Group
        </Button>
        <Button
          color="info"
          style={{ margin: "10px", padding: "10px 20px", fontSize: "16px" }}
          onClick={navigateToAssignQuizzes}
        >
          Assign Surveys
        </Button>
      </div>
      <FooterBlack />
    </>
  );
};

export default GroupManagement;
