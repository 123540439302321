import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { NavLink } from "react-router-dom";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import Award31 from "assets/img/demo/publicspeaking.png";
import Award32 from "assets/img/demo/publicspeaking2.png";
import Award33 from "assets/img/demo/publicspeaking3.png";
import Award34 from "assets/img/demo/publicspeaking4.png";
import "../../Styling/App.css";

const PublicSpeakingChampionships = () => {
  return (
    <div>
      <AppNavbar />
      <CleanHeader />
      <Container className="px-4 py-5">
        <h2
          style={{
            color: "black",
            fontWeight: "bold",
            marginBottom: "clamp(20px, 5vw, 45px)",
            marginTop: "0px",
            fontSize: "clamp(1.5rem, 5vw, 2.5rem)",
            textAlign: "center",
          }}
        >
          2022 UP Public Speaking Championships
        </h2>
        <Row>
          <Col
            md="6"
            style={{
              color: "black",
              marginBottom: "clamp(15px, 2vw, 30px)",
              display: "flex",
              flexDirection: "column",
              gap: "clamp(15px, 2vw, 25px)",
            }}
          >
            <div>
              <p
                className="text-dark"
                style={{ fontSize: "clamp(1rem, 4vw, 1.25rem)" }}
              >
                Elevate Africa Academy launched the first-ever Public Speaking
                Championships at the University of Pretoria. This was a
                groundbreaking collaboration between Elevate Africa Academy and
                the UP SRC, directly contributing to our company's objective of
                amplifying African voices. Young people came and showed us just
                what they have to give towards the elevation of our country and
                continent.
              </p>
              <p
                className="text-dark"
                style={{ fontSize: "clamp(1rem, 4vw, 1.25rem)" }}
              >
                Seven finalists were chosen from among the 40+ contestants and
                competed in front of an eminent panel of judges that included
                Miss South Africa 2010 and businesswoman, Bokang Montjane
                Tshabalala, Sadika Fakir, Integrated Media & Digital Director at
                Tiger Brands, and Lennox Wasara, a podcaster and radio
                personality.
              </p>
              <p
                className="text-dark"
                style={{ fontSize: "clamp(1rem, 4vw, 1.25rem)" }}
              >
                Congratulations to Miss Onyinyechuku Maryjane Igbojinna, 2022 UP
                Public Speaking Champion. Her selected topic was "Content
                Creation as a Solution to Unemployment." Along with the winner,
                there was also a prize for the most improved speaker during the
                tournament, who was Sibusiso Mziyako.
              </p>
            </div>
          </Col>

          <Col
            md="6"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "clamp(20px, 5vw, 30px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                height: "100%",
              }}
            >
              <div style={{ flex: 2 }}>
                <img
                  src={Award31}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "clamp(300px, 40vw, 400px)",
                    objectFit: "contain",
                  }}
                  alt="Public speaking championship 1"
                />
              </div>
              <div style={{ flex: 1 }}>
                <img
                  src={Award32}
                  style={{
                    width: "100%",
                    height: "auto",
                    maxHeight: "clamp(300px, 40vw, 400px)",
                    objectFit: "contain",
                  }}
                  alt="Public speaking championship 2"
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* Back Button - Now Left Aligned */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start", // Changed from center to flex-start
            marginTop: "2rem",
            marginBottom: "2rem",
            paddingLeft: "15px", // Added padding to align with the container content
          }}
        >
          <Button
            color="primary"
            tag={NavLink}
            to="/about-us"
            style={{
              width: "clamp(120px, 15vw, 150px)",
              textAlign: "center",
              fontSize: "clamp(1rem, 3vw, 1.1rem)",
            }}
            className="learn-more-btn"
          >
            Back
          </Button>
        </div>
      </Container>
      <FooterBlack />
    </div>
  );
};

export default PublicSpeakingChampionships;
