import { GET_PRODUCTS } from "endpoints/Endpoints";
import { getHttp } from "./genericHttp.service";


export const getProducts  = async () => {

    const response = await getHttp(GET_PRODUCTS).then((res) => {
        const {success, data, error} = {...res.data}
        return {success, data, error} ;
    }).catch((error) => {
        return { success: false, data: null, error: error}
    });

    return response;
}
