import React, { useState } from "react";
import { Col, Card, CardBody, CardTitle, CardText, Button } from "reactstrap";
import FeedbackModal from "./FeedbackModal";

const QuizCard = ({ quiz, isTaken, onTakeQuiz }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleFeedback = () => {
    setShowFeedback(!showFeedback);
  };

  const convertTimestamp = (timestamp) => {
    const milliseconds =
      timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
    const date = new Date(milliseconds);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const feedback = quiz.s_feedback
    ? quiz.s_feedback
        .slice()
        .sort((a, b) => b.score - a.score)
        .find((f) => f.score <= quiz.totalScore)
    : null;

  const cardStyle = {
    backgroundColor: "#f9f9f9",
    border: isHovered ? "0.2px solid #000080" : "0.2px solid #ddd",
    borderRadius: "8px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    cursor: "pointer",
  };

  return (
    <Col sm="4" className="mb-4">
      <Card
        className="shadow-sm"
        style={cardStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <CardBody className="d-flex flex-column justify-content-center">
          <CardTitle
            tag="h5"
            style={{
              textAlign: "left",
              color: "#050564",
              marginBottom: "15px",
            }}
          >
            {quiz.s_title}
          </CardTitle>
          <CardText style={{ fontSize: "16px", textAlign: "left" }}>
            {isTaken ? (
              <>
                <span style={{ fontWeight: "bold" }}>Total Points:</span>{" "}
                {quiz.surveyTotal}
                <br />
                <span style={{ fontWeight: "bold" }}>Points Scored:</span>{" "}
                {quiz.totalScore}
                <br />
                <span style={{ fontWeight: "bold" }}>Taken On:</span>{" "}
                {convertTimestamp(quiz.s_dateCompleted)}
                <br />
                <Button color="info" onClick={toggleFeedback}>
                  Show Feedback
                </Button>
                <FeedbackModal
                  isOpen={showFeedback}
                  toggle={toggleFeedback}
                  feedback={feedback}
                />
              </>
            ) : (
              <>
                <span style={{ fontWeight: "bold" }}>Created Date:</span>{" "}
                {convertTimestamp(quiz.s_lastModified)}
              </>
            )}
          </CardText>
          {!isTaken && (
            <Button color="info" onClick={() => onTakeQuiz(quiz.s_id)}>
              Take survey
            </Button>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default QuizCard;
