import React from "react";
import AccordionItem from "./AccordionItem";

const Accordion = ({ data }) => {
  return (
    <div id="acordeon" style={{ overflow: "hidden" }}>
      <div
        aria-multiselectable={true}
        id="accordion"
        role="tablist"
        style={{ maxHeight: "inherit" }}
      >
        {data.map((item) => (
          <AccordionItem
            key={item.id}
            id={item.id}
            title={item.title}
            content={item.content}
            expanded={item.expanded}
          />
        ))}
      </div>
    </div>
  );
};

export default Accordion;
