import React, { useState, useEffect } from "react";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import { getPrivacyPolicy } from "../service/privacyPolicy";

import Accordion from "components/Accordion/Accordion";

const sortPolicyDataById = (data) => {
  return data.sort((a, b) => a.id - b.id);
};

function PrivacyPolicy() {
  const [policyData, setPolicyData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPrivacyPolicy();
      const policyArrayData = sortPolicyDataById(data.data);
      setPolicyData(policyArrayData);
    };

    fetchData();
  }, []);

  return (
    <>
      <AppNavbar />
      <CleanHeader />

      <div
        style={{
          padding: "100px",
          backgroundColor: "white",
          paddingTop: "30px",
        }}
      >
        <h1
          style={{
            marginTop: "30px",
            fontWeight: "bold",
            color: "Black",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          Privacy Policy
        </h1>
        <Accordion data={policyData} />
      </div>
      <FooterBlack />
    </>
  );
}
export default PrivacyPolicy;
