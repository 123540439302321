import React from "react";
import {
  Card,
  CardImg,
  CardBody,
  CardTitle,
  Button,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";

const AwardCard = ({ imgSrc, title, description, link }) => (
  <Col md="12">
    <Card className="card-plain card-blog">
      <Row>
        <Col
          xs="12"
          md="4"
          style={{
            marginTop: { md: "28px" },
            display: "flex",
            justifyContent: "flex-start",
            padding: "20px 20px 0 20px",
          }}
        >
          <div className="card-image">
            <img
              alt="..."
              className="img img-raised"
              src={imgSrc}
              style={{
                height: "200px",
                width: "100%",
                maxWidth: "300px",
                objectFit: "contain",
              }}
            />
          </div>
        </Col>
        <Col xs="12" md="8">
          <CardBody
            style={{
              textAlign: "left",
              padding: "20px",
            }}
          >
            <CardTitle tag="h5">
              <a
                style={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "clamp(16px, 2.5vw, 20px)",
                  display: "block",
                  marginBottom: "10px",
                }}
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                {title}
              </a>
            </CardTitle>
            <p
              className="card-description"
              style={{
                fontWeight: "400",
                color: "black",
                marginBottom: "10px",
                fontSize: "clamp(14px, 2vw, 16px)",
                lineHeight: "1.6",
              }}
            >
              {description}
            </p>
            <Button
              className="learn-more-btn"
              color="info"
              tag={Link}
              to={link}
            >
              Read More
            </Button>
          </CardBody>
        </Col>
      </Row>
    </Card>
  </Col>
);

export default AwardCard;
