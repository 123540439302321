import React, { useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const FeedbackModal = ({ isOpen, toggle, feedback }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }

    return () => {
      document.body.classList.remove("modal-open");
    };
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{
        maxWidth: "80%",
        width: "80%",
        height: "80vh",
        margin: "50px auto 0 auto", // Add top margin to lower the modal
        padding: "0",
      }}
    >
      <ModalHeader
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "60px",
        }}
      >
        Quiz Feedback
      </ModalHeader>
      <ModalBody
        style={{
          height: "calc(80vh - 120px)",
          overflowY: "auto",
          padding: "20px",
        }}
      >
        {feedback ? (
          <>
            <p>
              <strong>Score:</strong> {feedback.score}
            </p>
            <p>
              <strong>Feedback:</strong> {feedback.feedback}
            </p>
          </>
        ) : (
          <p>No feedback available.</p>
        )}
      </ModalBody>
      <ModalFooter
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: "60px",
        }}
      >
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FeedbackModal;
