import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import Headroom from "headroom.js";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { getAuth } from "firebase/auth";

function AppNavbar() {
  const [navbarColor, setNavbarColor] = useState("navbar-transparent");
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();
        setIsAdmin(idTokenResult.claims.admin || false);
      }
      setLoading(false);
    });

    return () => {
      window.removeEventListener("scroll", updateNavbarColor);
      headroom.destroy();
    };
  }, [auth]);

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
    document.documentElement.classList.toggle("nav-open", !isCollapsed);
  };

  const closeNavbar = () => {
    setIsCollapsed(false);
    document.documentElement.classList.remove("nav-open");
  };

  return (
    <>
      {isCollapsed && (
        <div
          id="bodyClick"
          onClick={closeNavbar}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1049,
            background: "transparent",
          }}
        />
      )}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
        style={{ zIndex: 1050 }}
      >
        <Container>
          <NavbarBrand id="navbar-brand" to="/home" tag={Link}>
            ELEVATE AFRICA ACADEMY
          </NavbarBrand>
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleNavbar}
            style={{
              width: "50px",
              height: "50px",
              border: "none",
              padding: "10px",
            }}
          >
            <span
              className="navbar-toggler-bar"
              style={{
                height: "3px",
                margin: "6px 0",
                backgroundColor: "white",
              }}
            />
            <span
              className="navbar-toggler-bar"
              style={{
                height: "3px",
                margin: "6px 0",
                backgroundColor: "white",
              }}
            />
            <span
              className="navbar-toggler-bar"
              style={{
                height: "3px",
                margin: "6px 0",
                backgroundColor: "white",
              }}
            />
          </button>
          <Collapse isOpen={isCollapsed} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/home" tag={Link}>
                  HOME
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/about-us" tag={Link}>
                  ABOUT US
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/public-speaking" tag={Link}>
                  PUBLIC SPEAKING
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/voice-over" tag={Link}>
                  VOICE OVER
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/alumni" tag={Link}>
                  ALUMNI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/form" tag={Link}>
                  SURVEYS
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={closeNavbar} to="/contact-us" tag={Link}>
                  CONTACT US
                </NavLink>
              </NavItem>
              {isAdmin && !loading && (
                <NavItem>
                  <NavLink onClick={closeNavbar} to="/admin-home" tag={Link}>
                    ADMIN
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <Button
                  color="info"
                  onClick={() => {
                    navigate("/book-now");
                    closeNavbar();
                  }}
                  className="learn-more-btn"
                >
                  BOOK NOW
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default AppNavbar;
