import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
} from "reactstrap";
import "../../Styling/HeaderCarousel.css";

const items = [
  {
    src: require("../../assets/img/ElevateAfrica_White-2.png"),
    altText: "Testing",
    caption: "MASTER THE ART OF PUBLIC SPEAKING AND VOICE OVER SKILLS",
    text: "Communicate effectively with Elevate Africa Academy and take your career to the next level with public speaking and voice-over acting training courses on offer.",
    overlayColor: "rgba(6, 84, 196, 0)",
    backgroundSize: "contain",
  },
  {
    src: require("../../assets/img/why-train.jpeg"),
    altText: "Testing",
    caption: "WHY TRAIN WITH US?",
    text: "The ability to effectively communicate is an essential skill for any career from the next generation of leaders to entrepreneurs, teachers, and every other vocation that requires human connection.",
    overlayColor: "rgba(6, 84, 196, 0.2)",
    backgroundSize: "cover",
  },
  {
    src: require("../../assets/img/mic-1867121_1280.jpg"),
    altText: "Slide 4",
    caption: "VOICE OVER ACTING",
    text: "The voice-over industry is continuously growing, with vast opportunities for people of different languages, accents, and styles. We work to create eloquent, confident, and marketable speakers.",
    overlayColor: "rgba(3,23,42, 0.5)",
    backgroundSize: "cover",
  },
  {
    src: require("../../assets/img/public-speak.jpeg"),
    altText: "Slide 2",
    caption: "PUBLIC SPEAKING",
    text: "Public speaking is an underutilized tool which has the power to open a lot of doors. Elevate Africa Academy was created to harness the full power of public speaking on the African Continent.",
    //overlayColor: "rgba(13,23,42, 0.5)",
    backgroundSize: "cover",
  },
];

function HeaderWithCarousel({ className }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!animating) {
        next();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [animating]);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <div
          className="carousel-background"
          style={{
            borderRadius: "10px",
            backgroundImage: `url(${item.src})`,
            backgroundSize: item.backgroundSize,
            backgroundColor: item.overlayColor,
            backgroundBlendMode: "overlay",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
            width: "100%",
            height: "0",
            paddingBottom: "75%",
            position: "relative",
          }}
        />
      </CarouselItem>
    );
  });

  return (
    <div
      style={{
        position: "relative",
        minHeight: "70vh",
        paddingTop: "80px",
      }}
    >
      {/* Blurred background */}
      <div
        className="blurred-background"
        style={{
          backgroundImage: `url(${require("../../assets/img/sections/mic-background.jpg")})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
          filter: "blur(9px)",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 0,
        }}
      />

      {/* Overlay */}
      <div
        className="filter"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(6, 84, 196, 0.4)",
          zIndex: 1,
        }}
      />
      {/* Dark Overlay for additional depth */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.7))", // Dark gradient overlay
          zIndex: 1,
        }}
      />
      {/* Content */}
      <Container
        fluid
        style={{
          position: "relative",
          zIndex: 2,
          padding: "2rem 2.5rem",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Row className="w-100 align-items-center">
          <Col xs={12} md={6} className="mb-4 mb-md-0">
            <div style={{ margin: "0 auto", maxWidth: "100%" }}>
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
                style={{ zIndex: 3 }}
              >
                {slides}
                <CarouselControl
                  direction="prev"
                  directionText="Previous"
                  onClickHandler={previous}
                />
                <CarouselControl
                  direction="next"
                  directionText="Next"
                  onClickHandler={next}
                />
              </Carousel>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              style={{
                padding: "1rem 2rem",
                color: "white",
                textAlign: "center",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h2
                style={{
                  marginBottom: "1rem",
                  fontSize: "clamp(1.5rem, 5vw, 3rem)", // Responsive heading
                  lineHeight: "1.2",
                }}
              >
                {items[activeIndex].caption}
              </h2>
              <p
                style={{
                  fontSize: "clamp(1rem, 3.5vw, 1.25rem)", // Responsive paragraph text
                  lineHeight: "1.5",
                  margin: "0 auto",
                  maxWidth: "90%",
                }}
              >
                {items[activeIndex].text}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HeaderWithCarousel;
