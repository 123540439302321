import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { useNavigate } from "react-router-dom";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AdminHeader from "components/Headers/AdminHeader";
const SurveyList = () => {
  const navigate = useNavigate();

  const navigateToSurveyManagement = () => {
    navigate("/survey-management");
  };

  const navigateToGroupManagement = () => {
    navigate("/group-management");
  };

  return (
    <>
      <AppNavbar />
      <AdminHeader />
      <div style={{ padding: "20px", textAlign: "center" }}>
        <h2 style={{ margin: "30px", marginLeft: "0px" }}>Management</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          <Card
            color="info"
            style={{
              flex: "1 1 calc(33.33% - 20px)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              cursor: "pointer",
              backgroundColor: "#000080",
              color: "white",
            }}
            onClick={navigateToSurveyManagement}
          >
            <CardBody>
              <CardTitle tag="h3" style={{ color: "white" }}>
                Survey Management
              </CardTitle>
            </CardBody>
          </Card>
          <Card
            color="info"
            style={{
              flex: "1 1 calc(33.33% - 20px)",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "40px",
              cursor: "pointer",
              backgroundColor: "#000080",
              color: "white",
            }}
            onClick={navigateToGroupManagement}
          >
            <CardBody>
              <CardTitle tag="h3" style={{ color: "white" }}>
                Group Management
              </CardTitle>
            </CardBody>
          </Card>
        </div>
      </div>
      <FooterBlack />
    </>
  );
};

export default SurveyList;
