import React, { useState, useEffect } from "react";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";
import Accordion from "components/Accordion/Accordion";

const termsData = [
  {
    id: 1,
    expanded: false,
    title: "General Terms",
    content: `
      <p><strong>Last update: September 05, 2024</strong></p>
      <p>By accessing and placing an order with Elevate Africa Academy, you confirm
      that you are in agreement with and bound by the terms and conditions
      outlined below. These terms apply to the entire website and any email or other type of communication
      between you and Elevate Africa Academy.</p>
      <p>Under no circumstances shall Elevate Africa Academy be liable for any
      direct, indirect, special, incidental, or consequential damages,
      including, but not limited to, loss of data or profit, arising out
      of the use, or the inability to use, the materials on this site,
      even if Elevate Africa Academy or an authorized representative has been
      advised of the possibility of such damages.</p>
    `,
  },
  {
    id: 2,
    expanded: false,
    title: "Courses and Services",
    content: `
      <p>Elevate Africa Academy offers public speaking and voice-over lessons. You can browse, purchase, and enroll in our courses directly through this website. Upon successful purchase, you will have access to the course materials and related services, downloadable content, and interactive surveys.</p>
    `,
  },
  {
    id: 3,
    expanded: false,
    title: "Purchases",
    content: `
      <p>All purchases made on this site for courses or services are final. You are responsible for ensuring that your payment details are accurate at the time of purchase. In case of payment issues, contact our support team for assistance.</p>
    `,
  },
  {
    id: 4,
    expanded: false,
    title: "Surveys",
    content: `
      <p>As part of our services, you may be asked to participate in surveys that help us gather feedback on your experience and improve our courses. Participation in surveys is voluntary, and your responses will remain confidential.</p>
    `,
  },
  {
    id: 5,
    expanded: false,
    title: "Cookie Policy",
    content: `
      <p>Our website uses cookies to enhance your user experience. By using our website and agreeing to this policy, you consent to the use of cookies in accordance with the terms of this policy.</p>
      <p>We use cookies to track your progress on our courses and to improve your overall experience on the site.</p>
    `,
  },
  {
    id: 6,
    expanded: false,
    title: "Payments",
    content: `
      <p>We process all payments securely through our third-party payment provider. Your financial information is protected and is not stored on our servers. Any issues related to payments should be directed to our support team.</p>
    `,
  },
  {
    id: 7,
    expanded: false,
    title: "Refunds",
    content: `
      <p>Refunds are provided only in exceptional cases, such as incorrect billing or technical issues that prevent access to course content. Refund requests should be submitted within 7 days of purchase, along with an explanation of the issue.</p>
    `,
  },
  {
    id: 8,
    expanded: false,
    title: "Changes to Terms",
    content: `
      <p>If we change our terms of use, we will post those changes on this page. Registered users will be notified via email of any significant updates to the terms and conditions.</p>
    `,
  },
];

const sortTermsDataById = (data) => {
  return data.sort((a, b) => a.id - b.id);
};

function TermsAndConditions() {
  const [termsDataState, setTermsDataState] = useState([]);

  useEffect(() => {
    // Sort the data when component loads
    const sortedData = sortTermsDataById(termsData);
    setTermsDataState(sortedData);
  }, []);

  return (
    <>
      <AppNavbar />
      <CleanHeader />

      <div
        style={{
          padding: "100px",
          backgroundColor: "white",
          paddingTop: "30px",
        }}
      >
        <h1
          style={{
            marginTop: "30px",
            fontWeight: "bold",
            color: "Black",
            textAlign: "center",
            marginBottom: "30px",
          }}
        >
          Terms and Conditions
        </h1>
        <Accordion data={termsDataState} />
      </div>
      <FooterBlack />
    </>
  );
}

export default TermsAndConditions;
