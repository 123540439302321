import React from "react";

const VideoComponent = ({ src, width, height }) => {
  return (
    <div className="iframe-container">
      <iframe
        width={width}
        height={height}
        src={src}
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoComponent;
