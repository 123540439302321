import { GET_USER_BY_ID } from "endpoints/Endpoints";
import { getHttp, httpPost } from "./genericHttp.service";
import { CREATE_UPDATE_USER } from "endpoints/Endpoints";
import { GET_COMPLETED_SURVEYS } from "endpoints/Endpoints";
import { GET_ALL_USERS } from "endpoints/Endpoints";

export const getUserById = async (user_id) => {
  const response = await getHttp(GET_USER_BY_ID(user_id))
    .then((res) => {
      return { success: true, data: res.data, error: null };
    })
    .catch((error) => {
      return { success: false, data: null, error: error };
    });

  return response;
};

export const createUpdateUser = async (userData) => {
  const response = await httpPost(CREATE_UPDATE_USER, userData)
    .then((res) => {
      return { success: true, data: res.data, error: null };
    })
    .catch((error) => {
      return { success: false, data: null, error: error };
    });

  return response;
};

export const loginCreate = async (user_id, email, displayName) => {
  const userResponse = await getUserById(user_id);
  if (!userResponse.data.success) {
    const userData = {
      uid: user_id,
      email: email,
      fullName: displayName ?? "",
    };
    const createResponse = await createUpdateUser(userData);

    if (createResponse.success) {
      // navigate user to page;
      return { success: true };
    } else {
      // go not log user in
      return { success: false };
    }
  }

  return { success: true };
};

export const getCompletedSurveys = async (user_id) => {
  const response = await getHttp(GET_COMPLETED_SURVEYS(user_id))
    .then((res) => {
      return { success: true, data: res.data, error: null };
    })
    .catch((error) => {
      return { success: false, data: null, error: error };
    });

  return response;
};

export const getAllUsers = async () => {
  const response = await getHttp(GET_ALL_USERS)
    .then((res) => {
      return { success: true, data: res.data, error: null };
    })
    .catch((error) => {
      return { success: false, data: null, error: error };
    });

  return response;
};
