import React, { useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import CleanHeader from "components/Headers/CleanHeader";

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage =
    new URLSearchParams(location.search).get("message") ||
    "An error occurred during the payment process.";

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <Container className="py-5" style={{ minHeight: "calc(100vh - 200px)" }}>
        <Row className="justify-content-center">
          <Col md={8} lg={6}>
            <div className="text-center p-5 bg-white shadow rounded">
              <svg
                className="mx-auto mb-4"
                width="64"
                height="64"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                style={{ color: "#dc3545" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                ></path>
              </svg>
              <h1 className="h3 mb-4">Payment Failed</h1>
              <p className="mb-4">{errorMessage}</p>
              <p className="text-muted mb-4">
                Redirecting to homepage in 5 seconds...
              </p>
              <Button tag={Link} to="/" color="info" className="mr-2">
                Return to Home
              </Button>
              <Button tag={Link} to="/book-now" color="success">
                Try Again
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </>
  );
};

export default ErrorPage;
