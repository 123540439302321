import axios from "axios";

/**
 * Fetches the collection of privacy policies items data from the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an empty array.
 *
 * @returns {Promise<Array>} A promise that resolves to the privacy policy data array.
 */
export const getPrivacyPolicy = async () => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/privacyPolicy/`

      //`${process.env.REACT_APP_API_URL}/api/privacyPolicy/`
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return response.data;
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching privacy policy items data:", error);

    // Return an empty array to signify failure to fetch data
    return [];
  }
};
