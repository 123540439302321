import React, { useState, useEffect, useContext } from "react";
import * as styles from "./login.css";
import {
  Button,
  Card,
  Input,
  Container,
  Row,
  Col,
  FormGroup,
} from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import {
  loginWithEmailPassword,
  loginWithGooglePopup,
} from "../services/auth.service";
import { loginCreate } from "../services/user.service";
import { auth } from "../firebase-config/firebase-config"; // Adjust the path as per your Firebase configuration

function LoginPage() {
  const location = useLocation();
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  const [loginIn, setLoginIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState(null); // State to hold the token

  useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("full-screen");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    return () => {
      document.body.classList.remove("login-page");
      document.body.classList.remove("full-screen");
    };
  }, []);

  const handleLogin = async () => {
    setLoginIn(true);
    setErrorMessage("");

    const response = await loginWithEmailPassword(
      userData.email,
      userData.password
    );

    if (response.success) {
      setLoginIn(false);
      fetchAndSetToken(); // Fetch and set the token after successful login
      handleLoginRedirect(); // Redirect after the alert
    } else {
      setLoginIn(false);
      if (response.error.code === "auth/invalid-credential") {
        setErrorMessage("Invalid username or password");
      }
      console.error(response);
    }
  };

  const fetchAndSetToken = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const idToken = await user.getIdToken();
        localStorage.setItem("accessToken", idToken); // Store token in localStorage
        setToken(idToken);
      } else {
        console.error("No user logged in.");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  const handleRegister = () => {
    navigate("/register-page");
  };

  const handleLoginRedirect = () => {
    const redirectUrl = location.search.includes("redirectUrl")
      ? location.search.split("redirectUrl=")[1]
      : "/home";
    navigate(redirectUrl);
    alert("You have logged in successfully!");
  };

  const handleGooglePopup = async () => {
    const response = await loginWithGooglePopup();

    if (response.success) {
      setLoginIn(true);

      const loginResponse = await loginCreate(
        response.data.uid,
        response.data.email,
        response.data?.displayName ?? ""
      );

      if (loginResponse.success) {
        setLoginIn(false);
        fetchAndSetToken(); // Fetch and set the token after successful login
        handleLoginRedirect();
      } else {
        setLoginIn(false);
        console.error("Failed to create user:", loginResponse.error);
      }
    }
  };

  return (
    <div className="wrapper">
      <div
        className="page-header"
        style={{
          backgroundImage:
            "url(" + require("assets/img/login-back-1.jpg") + ")",
        }}
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="5">
              <Card className="card-register">
                <h3
                  style={{
                    marginTop: "0px",
                    textAlign: "center",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  LOGIN
                </h3>
                <FormGroup className="label-floating">
                  <label>Email</label>
                  <Input
                    placeholder="Email"
                    type="email"
                    onChange={(e) =>
                      setUserData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                  <label>Password</label>
                  <Input
                    placeholder="Password"
                    type="password"
                    onChange={(e) =>
                      setUserData((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: "red",
                      marginTop: "15px",
                    }}
                  >
                    {errorMessage}
                  </p>
                  <Button
                    onClick={handleLogin}
                    block
                    color="info"
                    disabled={loginIn}
                  >
                    Login
                  </Button>

                  <Button onClick={handleGooglePopup} block color="info">
                    Login with Google
                  </Button>

                  <p
                    className="card-description"
                    style={{ textAlign: "center", color: "black" }}
                  >
                    New on our platform?{" "}
                    <span onClick={handleRegister} className="sign-up-link">
                      Create an account
                    </span>
                  </p>
                </FormGroup>
                <div className="forgot" style={{ cursor: "pointer" }}>
                  <a
                    style={{ color: "black" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <u>Forgot password?</u>
                  </a>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}

export default LoginPage;
