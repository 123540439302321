import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import BannerHeader from "./BannerHeader";
import BannerCard from "./BannerCard";

const Banner = ({
  backgroundImage,
  title,
  description,
  showLogo = true,
  logoSrc,
  cards = [],
  cardsAlignment = "center",
}) => {
  return (
    <>
      <BannerHeader
        backgroundImage={backgroundImage}
        title={title}
        description={description}
        showLogo={showLogo}
        logoSrc={logoSrc}
      />
      {cards.length > 0 && (
        <Container
          className="bannerCardsContainer"
          style={{ marginTop: "-100px" }}
        >
          {" "}
          {/* Adjusted negative margin to pull cards up */}
          <Row className={`justify-content-${cardsAlignment}`}>
            {cards.map((card, index) => (
              <Col md="4" key={index}>
                <BannerCard
                  title={card.title}
                  text={card.text}
                  color={card.color}
                />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

Banner.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  showLogo: PropTypes.bool,
  logoSrc: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired, // Each card now has a color property
    })
  ),
  cardsAlignment: PropTypes.string,
};

export default Banner;
