// firebase-config.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"; // Import getStorage

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC8J6-QMGAIyAzB-HakckBWOk61Ku4UsFA",
  authDomain: "elevate-africa-7c1a6.firebaseapp.com",
  projectId: "elevate-africa-7c1a6",
  storageBucket: "elevate-africa-7c1a6.appspot.com",
  messagingSenderId: "824692599707",
  appId: "1:824692599707:web:d73041dd47cbfdfecacf3e",
  measurementId: "G-2DSC9MQBR1",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const storage = getStorage(app); // Initialize storage

export { app, auth, storage };
