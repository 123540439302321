import axios from "axios";

/**
 * Creates a new group with the given name and members.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an error object.
 *
 * @param {string} groupName - The name of the group to be created.
 * @param {Array<string>} memberIds - The list of member IDs to be added to the group.
 * @returns {Promise<Object>} A promise that resolves to the response data.
 */
export const createGroup = async (groupName, memberIds, g_category) => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.post(
      // `${process.env.REACT_APP_API_URL}/api/groups/create`,
      `${process.env.REACT_APP_API_URL}/groups/create`,
      { groupName, memberIds, g_category }
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return { success: true, data: response.data };
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error creating group:", error);

    // Return an error object to signify failure to create group
    return { success: false, error: error.message };
  }
};

export const assignSurveyToGroup = async (groupId, surveyId) => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.post(
      //`${process.env.REACT_APP_API_URL}/api/groups/assignSurvey`,
      `${process.env.REACT_APP_API_URL}/groups/assignSurvey`,
      { groupId, surveyId }
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return { success: true, data: response.data };
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error assigning survey to group:", error);

    // Return an error object to signify failure to assign survey
    return { success: false, error: error.message };
  }
};

/**
 * Fetches the collection of groups from the server.
 * Utilizes axios for HTTP requests and logs the response data.
 * In case of an error, it logs the error message and returns an empty array.
 *
 * @returns {Promise<Object>} A promise that resolves to an object containing success status, data, and error.
 */
export const getAllGroups = async () => {
  try {
    // Construct the full API URL using the environment variable
    const response = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/api/groups/getAllGroups`
      `${process.env.REACT_APP_API_URL}/groups/getAllGroups`
    );

    // Log the response data for debugging purposes

    // Return the data received from the server
    return { success: true, data: response.data };
  } catch (error) {
    // Log the error for debugging purposes
    console.error("Error fetching groups:", error);

    // Return an error object to signify failure to fetch data
    return { success: false, error: error.message };
  }
};

export const getGroupById = async (groupId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/groups/getGroupById/${groupId}`
    );
    return await response.json();
  } catch (error) {
    console.error("Error fetching group details:", error);
    return { success: false, message: "Error fetching group details" };
  }
};

export const updateGroup = async (groupId, data) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/groups/updateGroup/${groupId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    return await response.json();
  } catch (error) {
    console.error("Error updating group:", error);
    return { success: false, message: "Error updating group" };
  }
};
