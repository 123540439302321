import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import FooterBlack from "components/Footers/FooterBlack";
import CardComponent from "components/Video/CardComponent";
import { getAlumnis } from "../service/alumni";
import { getReviews } from "../service/reviews";
import Testimonials from "components/Testimonials";
import "../Styling/App.css";

function VoiceOver() {
  const [alumniData, setAlumniData] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAlumnis();
      const reviews = await getReviews();
      const alumniArrayData = data.data;
      const reviewsArrayData = reviews.data;
      setAlumniData(alumniArrayData);
      setReviewsData(reviewsArrayData);
    };

    fetchData();
  }, []);

  return (
    <>
      <div
        className="section section-blog"
        style={{
          backgroundColor: "white",
          marginTop: "0px",
          padding: "0px",
        }}
      >
        <Container style={{ marginTop: "0px", marginBottom: "1px" }}>
          <Row>
            {alumniData.map((alumni, index) => (
              <Col md="4" key={index}>
                <CardComponent
                  videoSrc={alumni.a_uri}
                  videoWidth={"640px"}
                  videoHeight={"720px"}
                  name={alumni.a_title}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Testimonials />
    </>
  );
}

export default VoiceOver;
