import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import FooterBlack from "components/Footers/FooterBlack";
import CardComponent from "components/Video/CardComponent";
import { getPublicSpeakingCollection } from "../service/alumni";
import { getReviews } from "../service/reviews";
import Testimonials from "components/Testimonials";
import "../Styling/App.css";

function PublicSpeakingAlumni() {
  const [publicSpeakingData, setPublicSpeakingData] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getPublicSpeakingCollection();
      const reviews = await getReviews();
      const publicSpeakingDataArray = data.data;
      const reviewsArrayData = reviews.data;
      setPublicSpeakingData(publicSpeakingDataArray);
      setReviewsData(reviewsArrayData);
    };
    fetchData();
  }, []);

  return (
    <>
      <div
        className="section section-blog"
        style={{ marginTop: "0px", padding: "0px" }}
      >
        <Container style={{ marginTop: "0px", marginBottom: "1px" }}>
          <Row>
            {publicSpeakingData.map((alumni, index) => (
              <Col md="4" key={index}>
                <CardComponent
                  videoSrc={alumni.p_uri}
                  name={alumni.p_title}
                  showBookButton={false} // Set to false for public speaking alumni
                />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
      <Testimonials />
    </>
  );
}

export default PublicSpeakingAlumni;
