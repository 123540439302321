import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getAllUsers, getUserById } from "../../services/user.service";
import { getGroupById, updateGroup } from "../../service/groups";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  InputGroup,
  Spinner,
} from "reactstrap";
import AppNavbar from "components/Navbars/AppNavbar";
import FooterBlack from "components/Footers/FooterBlack";
import AdminHeader from "components/Headers/AdminHeader";

const EditGroup = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const [groupName, setGroupName] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchGroupDetails = async () => {
      try {
        const response = await getGroupById(groupId);
        if (response.success) {
          setGroupName(response.group.groupName);
          const members = await Promise.all(
            response.group.members.map(async (userId) => {
              const userResponse = await getUserById(userId);
              return userResponse.data.data;
            })
          );
          setSelectedUsers(members);
        }
      } catch (error) {
        console.error("Error fetching group details:", error);
      }
    };

    fetchGroupDetails();
    fetchUsers();
  }, [groupId]);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await getAllUsers();
      if (response.success) {
        setUsers(response.data);
      } else {
        console.error("Error fetching users:", response.message);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSaveGroup = async () => {
    if (groupName.trim() === "") {
      alert("Group name is required");
      return;
    }

    const membersArray = selectedUsers.map((user) => user.uid);
    const data = {
      groupName,
      members: membersArray,
    };

    const response = await updateGroup(groupId, data);

    if (response.success) {
      alert("Group updated successfully!");
      navigate("/group-management");
    } else {
      alert(response.message);
    }
  };

  const handleAddUserToGroup = (user) => {
    setSelectedUsers((prevUsers) => [...prevUsers, user]);
  };

  const handleRemoveUserFromGroup = (userId) => {
    setSelectedUsers((prevUsers) =>
      prevUsers.filter((user) => user.uid !== userId)
    );
  };

  const filteredUsers = users.filter(
    (user) =>
      user.fullName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <AppNavbar />
      <AdminHeader />
      <div style={{ padding: "20px" }}>
        <h2 style={{ margin: "30px", marginLeft: "0px" }}>Edit Group</h2>
        <Form>
          <FormGroup>
            <Label for="groupName">Group Name</Label>
            <Input
              type="text"
              name="groupName"
              id="groupName"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="memberIds">Existing Members</Label>
            <ListGroup>
              {selectedUsers.map((user) => (
                <ListGroupItem key={user.uid}>
                  {user.fullName || user.email}
                  <Button
                    color="danger"
                    size="sm"
                    style={{ float: "right" }}
                    onClick={() => handleRemoveUserFromGroup(user.uid)}
                  >
                    Remove
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          </FormGroup>
          <h2 style={{ margin: "30px", marginLeft: "0px" }}>Add New Members</h2>
          <InputGroup
            style={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Input
              type="text"
              placeholder="Search users..."
              value={searchQuery}
              onChange={handleSearch}
              style={{ marginRight: "10px" }}
            />
          </InputGroup>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Spinner color="info" />
            </div>
          ) : filteredUsers.length === 0 ? (
            <div style={{ textAlign: "center", margin: "20px 0" }}>
              No users found
            </div>
          ) : (
            <ListGroup>
              {filteredUsers.slice(0, 5).map((user) => (
                <ListGroupItem key={user.id}>
                  {user.fullName || user.email}
                  <Button
                    color="info"
                    size="sm"
                    style={{ float: "right" }}
                    onClick={() => handleAddUserToGroup(user)}
                    disabled={selectedUsers.some(
                      (selected) => selected.uid === user.id
                    )}
                  >
                    Add to Group
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          )}
          <Button color="info" onClick={handleSaveGroup} className="mt-4">
            Save Group
          </Button>
        </Form>
      </div>
      <FooterBlack />
    </>
  );
};

export default EditGroup;
