import React, { useContext } from "react";
import { GameStateContext } from "../../assets/Helpers/gameContexts";
import {
  Card,
  CardBody,
  Button,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import AppNavbar from "components/Navbars/AppNavbar";
import CleanHeader from "components/Headers/CleanHeader";
import FooterBlack from "components/Footers/FooterBlack";
import "../../Styling/Form.css";

const EndScreen = () => {
  const { score, setScore, setGameState, userName } =
    useContext(GameStateContext);
  const navigate = useNavigate();

  const restartQuiz = () => {
    setScore(0);
    setGameState("menu");
  };

  const exitQuiz = () => {
    setGameState("menu");
    navigate("/form");
  };

  return (
    <>
      <AppNavbar />
      <CleanHeader />
      <Container className="Quiz bg-grey py-5" style={{ marginTop: "40px" }}>
        <Row className="justify-content-center mb-4">
          <Col md="8">
            <Card
              style={{
                marginTop: "40px",
                border: "0.01px solid",
                width: "100%",
                borderColor: "grey",
                borderRadius: "15px",
                padding: "20px",
              }}
            >
              <CardTitle className="text-center">
                <h2 style={{ fontSize: "40px", color: "#050564" }}>
                  Survey Successfully Completed
                </h2>
              </CardTitle>
              <CardBody className="text-center">
                <h3 style={{ color: "Black" }}></h3>
                <div className="mt-4 d-flex justify-content-between">
                  <Button
                    onClick={restartQuiz}
                    style={{
                      flex: 1,
                      marginRight: "10px",
                      backgroundColor: "#050564",
                      color: "white",
                    }}
                  >
                    Restart Quiz
                  </Button>
                  <Button
                    onClick={exitQuiz}
                    style={{
                      flex: 1,
                      backgroundColor: "#050564",
                      color: "white",
                    }}
                  >
                    Exit
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterBlack />
    </>
  );
};

export default EndScreen;
