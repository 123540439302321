import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, CardText } from "reactstrap";

const BannerCard = ({ title, text, color }) => {
  return (
    <Card
      style={{
        height: "300px",
        backgroundColor: `rgba(255, 255, 255, 0.6)`,
        backdropFilter: "blur(10px)",
        border: "1px solid rgba(255, 255, 255, 0.1)",
      }}
      className="bannerCard"
    >
      <CardBody>
        <CardTitle className="bannerCardTitle">
          <h4 style={{ fontWeight: "bold", marginTop: "0px", color: "#000" }}>
            {title}
          </h4>
        </CardTitle>
        <div className="space-top" />
        <CardText style={{ fontWeight: "400", fontSize: 16, color: "#000" }}>
          {text}
        </CardText>
      </CardBody>
    </Card>
  );
};

BannerCard.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default BannerCard;
