import { POST_INITIALIZE_OZOW_PAYMENT } from "../endpoints/Endpoints";
import { httpPost } from "./genericHttp.service";

export const initializePayment = async (data) => {
  const response = await httpPost(POST_INITIALIZE_OZOW_PAYMENT, data)
    .then((res) => {
      return { success: true, data: res.data, error: null };
    })
    .catch((error) => {
      return { success: false, data: null, error: error };
    });

  return response;
};
